import { companiesPartnersApi } from 'api';
import Loader from 'components/Loader';
import { fetchCompany } from 'pages/companies/store';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { BasicsWrapper, Field, FieldsWrapper, MuiDivider, MuiTextField, SubTitle } from './styles';

const serviceTypesOptions = [
    { label: 'Выбрать все', value: 'all' },
    { label: 'Установка', value: 'installation' },
    { label: 'Коммерческий ремонт', value: 'repair' },
    { label: 'Гарантийный ремонт', value: 'warranty_repair' },
];

export const CompanyMainPage = () => {
    const dispatch = useAppDispatch();

    const { company, loading } = useSelector(companiesPartnersSelector);

    const handleCopy = (value: string) => {
        navigator.clipboard.writeText(value);
        enqueueSnackbar('Скопировано в буфер обмена.', { variant: 'success' });
    };

    const handleEdit = (data: any) => {
        if (!company) return;

        companiesPartnersApi
            .editCompany(company?.id, data)
            .then(() => {
                dispatch(fetchCompany(company?.id));

                enqueueSnackbar('Изменения сохранены', {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const defaultServiceTypes = useMemo(() => {
        if (company?.service_types?.[0]) {
            return company?.service_types?.map((item: string) => ({
                label: serviceTypesOptions.find((option) => option.value === item)?.label || '',
                value: item,
            }));
        }
        return [];
    }, [company?.service_types]);

    if (loading) return <Loader />;

    return (
        <BasicsWrapper>
            <div>
                <MuiDivider sx={{ width: '600px' }} />
                <FieldsWrapper>
                    <Field>
                        <SubTitle>Идентификатор</SubTitle>
                        <Tooltip
                            title={'Копировать'}
                            placement="right"
                        >
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleCopy(company?.id as string)}
                            >
                                {company?.id}
                            </div>
                        </Tooltip>
                    </Field>
                    <Field>
                        <SubTitle>Наименованиие</SubTitle>
                        <MuiTextField
                            onBlur={(e) => {
                                if (e.target.value === company?.name) return;
                                handleEdit({ name: e.target.value });
                            }}
                            defaultValue={company?.name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Тип услуг</SubTitle>
                        <Autocomplete
                            sx={{ width: '392px' }}
                            multiple
                            size="small"
                            popupIcon={<ExpandMoreIcon />}
                            options={serviceTypesOptions}
                            value={defaultServiceTypes || []}
                            filterSelectedOptions
                            onChange={(_, option, reason, details) => {
                                if (
                                    reason === 'selectOption' &&
                                    company?.service_types?.includes(details?.option.value as string)
                                ) {
                                    return;
                                }

                                const service_types = option?.map((item) => item.value);

                                if (reason === 'clear') {
                                    handleEdit({ service_types: [] });
                                } else {
                                    if (details?.option?.value === 'all') {
                                        if (company?.service_types?.length === 3) return;

                                        handleEdit({
                                            service_types:
                                                reason === 'removeOption'
                                                    ? []
                                                    : ['installation', 'repair', 'warranty_repair'],
                                        });
                                    } else {
                                        handleEdit({ service_types });
                                    }
                                }
                            }}
                            renderInput={(params) => (
                                <MuiTextField
                                    {...params}
                                    placeholder="Выбрать"
                                    size="small"
                                />
                            )}
                        />
                    </Field>
                    <Field>
                        <SubTitle>Наименование партнера</SubTitle>
                        <MuiTextField
                            disabled
                            defaultValue={company?.partner_name}
                            size="small"
                        />
                    </Field>
                    <Field>
                        <SubTitle>Идентификатор партнера</SubTitle>
                        <Tooltip
                            title={'Копировать'}
                            placement="right"
                        >
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleCopy(company?.partner_id as string)}
                            >
                                {company?.partner_id}
                            </div>
                        </Tooltip>
                    </Field>
                </FieldsWrapper>
            </div>
        </BasicsWrapper>
    );
};
