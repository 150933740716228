import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { ICompany } from '../interfaces/companies';

export default class CompaniesAPI {
    getCompanies(params?: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<ICompany[]>;
    }

    getCompany(companyId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies/${companyId}`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    createCompany(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    editCompany(companyId: string, data?: any) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies/${companyId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    activateCompanyStatus(companyId: string) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies/${companyId}/activate`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    deactivateCompanyStatus(companyId: string) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/companies/${companyId}/deactivate`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    createUserCompany(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/users`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    activateUserStatus(userId: string) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/users/${userId}/active`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }

    deactivateUserStatus(userId: string) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/users/${userId}/disable`,
            isAuthorization: true,
        }) as AxiosPromise<ICompany>;
    }
}
