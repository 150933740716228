import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { setUser } from 'pages/companies/store';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';

import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { ChangeStatusModal } from './change-status';
import { CreateUser } from './create-user';
import { Cell, HeadCell, MuiDivider, ThreeDotsWrapper, Wrapper } from './styles';

export const CompanyUsersPage = () => {
    const dispatch = useAppDispatch();

    const { company } = useSelector(companiesPartnersSelector);
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [openChangeStatus, setOpenChangeStatus] = useState<any>(false);

    const isSuperAdmin = profile?.is_superadmin;
    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', height: 'calc(100% - 48px)' }}>
            {isSuperAdmin || isOwner || isCompanyOwner ? (
                <Button
                    variant="contained"
                    sx={{ position: 'absolute', right: '0', top: '-43px' }}
                    onClick={() => setIsOpen(true)}
                >
                    Добавить пользователя
                </Button>
            ) : null}

            <MuiDivider />
            <Wrapper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeadCell>EMAIL</HeadCell>
                                <HeadCell>ФАМИЛИЯ</HeadCell>
                                <HeadCell>ИМЯ</HeadCell>
                                <HeadCell>СТАТУС</HeadCell>
                                <HeadCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {company?.users?.map((user) => {
                                return (
                                    <TableRow key={user?.id}>
                                        <Cell>{user?.email}</Cell>
                                        <Cell>{user?.first_name}</Cell>
                                        <Cell>{user?.last_name}</Cell>
                                        <Cell>{user?.status === 'active' ? 'Активный' : 'Неактивный'}</Cell>
                                        <Cell>
                                            {isSuperAdmin || isOwner || isCompanyOwner ? (
                                                <ThreeDotsWrapper
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setOpenChangeStatus(e.currentTarget);
                                                        dispatch(setUser(user));
                                                    }}
                                                >
                                                    <ThreeDots />
                                                </ThreeDotsWrapper>
                                            ) : null}
                                        </Cell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Wrapper>

            <CreateUser
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
            <ChangeStatusModal
                isOpen={openChangeStatus}
                setIsOpen={setOpenChangeStatus}
            />
        </div>
    );
};
