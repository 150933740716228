import { Table as MuiTable } from '@mui/material';
import { ICompany } from 'interfaces/companies';
import { FC } from 'react';

import { Body } from './body';
import { Head } from './head';
import { TableWrapper } from './styles';

type Props = {
    rows: ICompany[];
};

export const Table: FC<Props> = ({ rows }) => {
    return (
        <TableWrapper>
            <MuiTable stickyHeader>
                <Head />
                <Body rows={rows} />
            </MuiTable>
        </TableWrapper>
    );
};
