import { Button } from 'components/Button';
import { WorkerStatusesMock } from 'mockData/workers';
import { useAppDispatch } from 'store/hooks';

import { Checkbox, FormControlLabel, TableBody, TableCell, TableRow, styled } from '@mui/material';
import { IWorker } from 'interfaces/workers';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { setWorkerId, setWorkerIds } from '../../store';
import { withdrawsSelector } from '../../store/selectors';
import { TDetailsStatusFilters } from '../../store/types';
import { WithdrawModal } from '../modals/withdraw';

type Props = {
    rows: IWorker[];
};

const employmentsMock: any = {
    ['self_employed']: { title: 'Самозанятый' },
    ['ie']: { title: 'ИП' },
    ['juridical_person']: { title: 'Юрлицо' },
};

const detailsStatusesMock: Record<TDetailsStatusFilters, string> = {
    verified: 'Проверены',
    not_verified: 'Не проверены',
    verify_processing: 'Проверяются',
    blocked: 'Заблокированы',
};

export const Body: FC<Props> = ({ rows }) => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const { workerIds } = useSelector(withdrawsSelector);

    const handleChange = (id: string, checked: boolean) => {
        if (!checked) {
            dispatch(setWorkerIds(workerIds.filter((itemId) => itemId !== id)));
        } else {
            dispatch(setWorkerIds([...workerIds, id]));
        }
    };

    return (
        <>
            <TableBody>
                {rows.map((row) => {
                    console.log(row);

                    return (
                        <TableRow key={row.id}>
                            <TableCell>
                                <Label
                                    disabled={
                                        row?.payment_details_entity_type !== 'self_employed' ||
                                        row?.payment_details_status !== 'verified'
                                    }
                                    checked={workerIds.includes(row.id) || false}
                                    control={<Checkbox />}
                                    label={`${row.last_name || ''} ${row.first_name || ''} ${row.middle_name || ''}`}
                                    onChange={(_, checked) => {
                                        handleChange(row.id, checked);
                                    }}
                                />
                            </TableCell>
                            <Cell>{WorkerStatusesMock[row.status]?.title}</Cell>
                            <Cell>
                                <NumericFormat
                                    displayType="text"
                                    value={row.balance}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">{row?.has_requisite ? 'Есть' : 'Нет'}</Cell>
                            <Cell align="center">
                                {row?.has_requisite ? employmentsMock[row?.payment_details_entity_type]?.title : '-'}
                            </Cell>
                            <Cell align="center">
                                {row?.payment_details_status ? detailsStatusesMock[row?.payment_details_status] : null}
                            </Cell>
                            <TableCell align="right">
                                <Button
                                    onClick={() => {
                                        dispatch(setWorkerId(row.id));
                                        setOpen(true);
                                    }}
                                    variant="contained"
                                    disabled={
                                        row?.payment_details_entity_type !== 'self_employed' ||
                                        row?.payment_details_status !== 'verified'
                                    }
                                >
                                    Вывести
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
            <WithdrawModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
});

const Label = styled(FormControlLabel)({
    margin: '0',
    '.MuiTypography-root': {
        fontWeight: '400',
        fontSize: '14px',
    },
});
