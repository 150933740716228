import { ERoutes } from 'enums/routes.enum';
import { TPrice } from 'pages/accounting/deposits/store/types';
import { calculatePercentage } from 'utils/helpers/calculatePercentage';

import { Table as MuiTable, styled, TableCell, TableBody, TableRow, TableHead } from '@mui/material';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

type Props = {
    rows: TPrice[];
    issueOid: number;
    issueCompanyName: string;
    issueId: string;
};

export const Table: FC<Props> = ({ rows, issueOid, issueCompanyName, issueId }) => {
    return (
        <MuiTable
            sx={{
                display: 'grid',
                gridTemplateColumns: '90px 130px 120px 130px 1fr 120px 100px 110px 100px 120px',
                gridAutoFlow: 'row',
                width: '100%',
            }}
        >
            <TableHead sx={{ display: 'contents' }}>
                <TableRow sx={{ display: 'contents' }}>
                    <HeadCell sx={{ paddingLeft: '2px' }}>ЗАЯВКА</HeadCell>
                    <HeadCell align="center">ПАРТНЕР</HeadCell>
                    <HeadCell align="center">ПЛАТЕЛЬЩИК</HeadCell>
                    <HeadCell align="center">ТИП УСЛУГИ</HeadCell>
                    <HeadCell align="center">НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                    <HeadCell align="center">ЦЕНА</HeadCell>
                    <HeadCell align="center">КОЛИЧЕСТВО</HeadCell>
                    <HeadCell align="center">СУММА</HeadCell>
                    <HeadCell align="center">ПРОЦЕНТ</HeadCell>
                    <HeadCell
                        align="center"
                        sx={{ paddingRight: '0' }}
                    >
                        КОМИССИЯ
                    </HeadCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ display: 'contents' }}>
                {rows.map((row) => {
                    return (
                        <TableRow
                            key={row.id}
                            sx={{ display: 'contents' }}
                        >
                            <Cell sx={{ paddingLeft: '2px' }}>
                                <Link
                                    to={`/${ERoutes.Issues}/${issueId}`}
                                    target="_blank"
                                >
                                    {issueOid}
                                </Link>
                            </Cell>
                            <Cell align="center">{issueCompanyName}</Cell>
                            <Cell align="center">
                                {!row.price_recipient ? '-' : row.price_recipient === 'client' ? 'Клиент' : 'Партнер'}
                            </Cell>
                            <Cell
                                align="center"
                                sx={{ maxWidth: '200px' }}
                            >
                                {row.price.service.name}
                            </Cell>
                            <Cell align="center">{row.price.name}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={row?.service_amount_with_discount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">{row?.quantity}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    disabled
                                    displayType="text"
                                    value={row?.total_discount_amount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">
                                {row.commission_type === 'fixation' ? (
                                    <span>{calculatePercentage(row?.commission_amount, row?.amount)}</span>
                                ) : (
                                    `${Math.floor(row.commission_amount * 100)} %`
                                )}
                            </Cell>
                            <Cell
                                align="center"
                                sx={{ paddingRight: '0' }}
                            >
                                <NumericFormat
                                    displayType="text"
                                    value={
                                        row.commission_type === 'fixation'
                                            ? row.commission_amount
                                            : row.amount * row.commission_amount
                                    }
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                    decimalScale={2}
                                />
                            </Cell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    alignContent: 'center',
});

const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
