import { Divider, styled, TableCell } from '@mui/material';

export const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    textAlign: 'center',
});

export const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    textAlign: 'center',
    width: '25%',
});

export const MuiDivider = styled(Divider)({
    color: 'rgba(0, 0, 0, 0.08)',
    marginBottom: '10px',
}); // double

export const ThreeDotsWrapper = styled('span')({
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 5px',
});

export const Wrapper = styled('div')({
    marginTop: '15px',
    position: 'relative',
    overflow: 'auto',
    flexGrow: 1,
    flexBasis: 0,
});
