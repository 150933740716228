import { historyApi } from 'api';
import { createSlice } from 'store/hooks';

import { PayloadAction } from '@reduxjs/toolkit';
import { TComment } from 'interfaces/history';

import { TState } from './types';

export const initialState: TState = {
    commentsHistory: [],

    choosedComment: null,

    historyId: null,

    historyModalIsOpen: false,

    loading: false,
};

const slice = createSlice({
    name: 'commentsHistory',
    initialState,
    reducers: (create) => ({
        fetchHistoryComments: create.asyncThunk(
            async (issueId, { dispatch }) => {
                const { data } = await historyApi.getHistoryAndComments(issueId, {
                    trigger__in: ['status', 'time_windows', 'canceled'],
                    order_by: '-created_at',
                });

                dispatch(setCommentsHistory(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setCommentsHistory: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.commentsHistory = payload;
        }),

        setChoosedComment: create.reducer((state, { payload }: PayloadAction<TComment | null>) => {
            state.choosedComment = payload;
        }),

        setHistoryId: create.reducer((state, { payload }: PayloadAction<string | null>) => {
            state.historyId = payload;
        }),

        setHistoryModalIsOpen: create.reducer((state, { payload }: PayloadAction<boolean>) => {
            state.historyModalIsOpen = payload;
        }),
    }),
});

export const { fetchHistoryComments, setCommentsHistory, setChoosedComment, setHistoryModalIsOpen, setHistoryId } =
    slice.actions;

export default slice.reducer;
