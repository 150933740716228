import { workersApi } from 'api';
import { Button } from 'components/Button';
import { FilterIn } from 'features/filter-in';
import { FilterIs } from 'features/filter-is';
import { useAppDispatch } from 'store/hooks';

import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button as MuiButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { WithdrawModal } from './components/modals/withdraw';
import { Table } from './components/table';
import { fetchWorkers, setEmploymentsFilter, setFilters, setWorkerId } from './store';
import { withdrawsSelector } from './store/selectors';
import { TDetailsStatusFilters, TEmploymentsFilters } from './store/types';
import { ListItem, StyledButton, StyledPopover, Toolbar } from './styles';

const requisitesItems = [
    { value: true, title: 'Есть' },
    { value: false, title: 'Нет' },
];

const filterItemsMapped: Record<'self_employed' | 'ie' | 'juridical_person', string> = {
    self_employed: 'Самозанятый',
    ie: 'ИП',
    juridical_person: 'Юрлицо',
};

const detailsStatusesMock: Record<TDetailsStatusFilters, string> = {
    verified: 'Проверены',
    not_verified: 'Не проверены',
    verify_processing: 'Проверяются',
    blocked: 'Заблокированы',
};

export const Withdraws = () => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [openEmploymentsFilter, setOpenEmploymentsFilter] = useState<any>(false);
    const [openDetailsStatusFilter, setOpenDetailsStatusFilter] = useState<any>(false);

    const { workers, workerIds, loading, filters, pagination, employmentsFilter } = useSelector(withdrawsSelector);

    const handleWithdrawWorkerIdsChange = (itemId: string) => {
        const getIds = () => {
            if (filters.pk__in.includes(itemId)) {
                return filters.pk__in.filter((id) => id !== itemId);
            } else {
                return [...filters.pk__in, itemId];
            }
        };

        dispatch(setFilters({ pk__in: getIds() }));
        dispatch(fetchWorkers());
    };

    const handleFilterInClear = (key: string) => {
        dispatch(setFilters({ [key]: [] }));
        dispatch(fetchWorkers());
    };

    const handleFilterIsRequisites = (value: boolean | null) => {
        if (value === false) {
            dispatch(setEmploymentsFilter(null));
        }
        dispatch(setFilters({ has_requisite: value }));
        dispatch(fetchWorkers());
    };

    const handleChangeEmploymentsFilter = (value: TEmploymentsFilters) => {
        dispatch(setFilters({ has_requisite: true }));
        dispatch(setEmploymentsFilter(value));
        dispatch(fetchWorkers());
        setOpenEmploymentsFilter(false);
    };

    const handleClearEmploymentsFilter = () => {
        dispatch(setEmploymentsFilter(null));
        dispatch(fetchWorkers());
        setOpenEmploymentsFilter(false);
    };

    const handleChangeDetailsStatusFilter = (value: TDetailsStatusFilters) => {
        dispatch(setFilters({ payment_details_status__in: [value] }));
        dispatch(fetchWorkers());
        setOpenDetailsStatusFilter(false);
    };

    const handleClearDetailsStatusFilter = () => {
        dispatch(setFilters({ payment_details_status__in: [] }));
        dispatch(fetchWorkers());
        setOpenDetailsStatusFilter(false);
    };

    useEffect(() => {
        dispatch(fetchWorkers());
    }, []);

    return (
        <>
            <Toolbar>
                <FilterIn
                    title="Мастер"
                    onChange={handleWithdrawWorkerIdsChange}
                    activeIds={filters.pk__in}
                    fetcher={(params) => workersApi.getWorkersNew({ size: 20, ...params })}
                    cleaner={() => handleFilterInClear('pk__in')}
                />
                <FilterIs
                    title="Реквизиты"
                    options={requisitesItems}
                    activeOption={
                        requisitesItems.find((item) => item.value === filters.has_requisite) ?? {
                            title: '',
                            value: null,
                        }
                    }
                    onChange={(value) => handleFilterIsRequisites(value)}
                />
                <MuiButton
                    variant={openEmploymentsFilter ? 'filterActive' : 'filter'}
                    onClick={(e) => setOpenEmploymentsFilter(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    {employmentsFilter ? filterItemsMapped[employmentsFilter] : 'Форма занятости'}
                </MuiButton>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(openEmploymentsFilter)}
                    anchorEl={openEmploymentsFilter}
                    onClose={() => setOpenEmploymentsFilter(false)}
                >
                    <div>
                        {Object.entries(filterItemsMapped).map(([value, title]) => {
                            return (
                                <ListItem
                                    sx={{ cursor: 'pointer' }}
                                    key={value}
                                    onClick={() => handleChangeEmploymentsFilter(value as TEmploymentsFilters)}
                                >
                                    {title as string}
                                    {employmentsFilter === value ? <CheckIcon /> : null}
                                </ListItem>
                            );
                        })}
                    </div>
                    <StyledButton onClick={handleClearEmploymentsFilter}>Очистить фильтр</StyledButton>
                </StyledPopover>
                <MuiButton
                    variant={openDetailsStatusFilter ? 'filterActive' : 'filter'}
                    onClick={(e) => setOpenDetailsStatusFilter(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    {filters.payment_details_status__in?.[0]
                        ? detailsStatusesMock[filters.payment_details_status__in?.[0]]
                        : 'Статус реквизитов'}
                </MuiButton>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(openDetailsStatusFilter)}
                    anchorEl={openDetailsStatusFilter}
                    onClose={() => setOpenDetailsStatusFilter(false)}
                >
                    <div>
                        {Object.entries(detailsStatusesMock).map(([value, title]) => {
                            return (
                                <ListItem
                                    sx={{ cursor: 'pointer' }}
                                    key={value}
                                    onClick={() => handleChangeDetailsStatusFilter(value as TDetailsStatusFilters)}
                                >
                                    {title as string}
                                    {filters.payment_details_status__in?.[0] === value ? <CheckIcon /> : null}
                                </ListItem>
                            );
                        })}
                    </div>
                    <StyledButton onClick={handleClearDetailsStatusFilter}>Очистить фильтр</StyledButton>
                </StyledPopover>

                {workerIds.length ? (
                    <Button
                        onClick={() => {
                            dispatch(setWorkerId(''));
                            setOpen(true);
                        }}
                        sx={{ marginLeft: 'auto' }}
                        variant="contained"
                    >
                        Вывести для выбранных
                    </Button>
                ) : null}
            </Toolbar>

            <Table
                loading={loading}
                rows={workers}
                pagination={pagination}
            />
            <WithdrawModal
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    );
};
