import { companiesPartnersApi } from 'api';
import { fetchCompany } from 'pages/companies/store';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { MuiDialog } from './styles';

import { ButtonsContainer, CloseIconWrapper, TitleWrapper } from '../create-user/styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

export const ChangeStatusModal: FC<Props> = ({ isOpen, setIsOpen }) => {
    const dispatch = useAppDispatch();

    const { user, company } = useSelector(companiesPartnersSelector);

    const handleChangeActive = () => {
        if (user?.status === 'active') {
            companiesPartnersApi
                .deactivateUserStatus(user?.id as string)
                .then(() => {
                    dispatch(fetchCompany(company?.id as string));
                    setIsOpen(false);

                    enqueueSnackbar('Пользователь деактивирован', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        } else {
            companiesPartnersApi
                .activateUserStatus(user?.id as string)
                .then(() => {
                    dispatch(fetchCompany(company?.id as string));
                    setIsOpen(false);

                    enqueueSnackbar('Пользователь активирован', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        }
    };

    return (
        <MuiDialog open={isOpen}>
            <DialogTitle>
                <TitleWrapper>
                    <Typography variant="h5">Подтвердите действие</Typography>
                    <CloseIconWrapper onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {`Вы действительно хотите ${
                        user?.status === 'active' ? 'деактивировать' : 'активировать'
                    } пользователя ${user?.first_name} ${user?.last_name} ?`}
                </Typography>
                <ButtonsContainer>
                    <Button
                        onClick={handleChangeActive}
                        variant="contained"
                    >
                        Да
                    </Button>
                    <Button
                        onClick={() => setIsOpen(false)}
                        variant="outlined"
                    >
                        Нет
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </MuiDialog>
    );
};
