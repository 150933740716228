import { companiesPartnersApi } from 'api';
import { fetchCompany, setUser } from 'pages/companies/store';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { ConfirmationModal } from './confirmation';
import { ButtonsContainer, CloseIconWrapper, TitleWrapper } from './styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

type TForm = {
    email: string;
    first_name: string;
    last_name: string;
    middle_name: string;
};

const initialState: TForm = {
    email: '',
    first_name: '',
    last_name: '',
    middle_name: '',
};

export const CreateUser: FC<Props> = ({ isOpen, setIsOpen }) => {
    const dispatch = useAppDispatch();

    const { company } = useSelector(companiesPartnersSelector);

    const [form, setForm] = useState<TForm>(initialState);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const handleCreateUser = () => {
        if (!company) return;

        companiesPartnersApi
            .createUserCompany({
                ...form,
                company_id: company?.id,
                invite_via_email: false,
                role_id: null,
                is_admin: false,
            })
            .then(({ data }) => {
                dispatch(fetchCompany(company?.id));
                dispatch(setUser(data));
                setOpenConfirmation(true);
            })
            .catch((e) => {
                enqueueSnackbar(
                    e.response.data?.detail?.[0]?.type === 'value_error.email'
                        ? 'Поле email заполнено неккоректно'
                        : e.response.data.error?.message.startsWith('User with email:')
                        ? 'Пользователь с таким email уже добавлен'
                        : e.response.data.error?.message || e.message,
                    {
                        variant: 'error',
                    },
                );
            });
    };

    const handleClose = () => {
        setForm(initialState);
        setIsOpen(false);
    };

    return (
        <Dialog
            fullWidth
            open={isOpen}
        >
            <DialogTitle>
                <TitleWrapper>
                    <Typography variant="h5">Добавить пользователя</Typography>
                    <CloseIconWrapper onClick={handleClose}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        sx={{ marginTop: '20px' }}
                        fullWidth
                        label="email"
                        onChange={(e) => {
                            setForm((prev) => {
                                return {
                                    ...prev,
                                    email: e.target.value,
                                };
                            });
                        }}
                    />
                    <TextField
                        sx={{ marginTop: '20px' }}
                        fullWidth
                        label="Фамилия"
                        onChange={(e) => {
                            setForm((prev) => {
                                return {
                                    ...prev,
                                    first_name: e.target.value,
                                };
                            });
                        }}
                    />
                    <TextField
                        sx={{ marginTop: '20px' }}
                        fullWidth
                        label="Имя"
                        onChange={(e) => {
                            setForm((prev) => {
                                return {
                                    ...prev,
                                    last_name: e.target.value,
                                };
                            });
                        }}
                    />
                </Box>
                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                    >
                        Отмена
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateUser}
                        disabled={!form?.email || !form?.first_name || !form?.last_name}
                    >
                        Сохранить
                    </Button>
                </ButtonsContainer>
            </DialogContent>

            <ConfirmationModal
                isOpen={openConfirmation}
                setIsOpen={setOpenConfirmation}
                setIsOpenCreate={setIsOpen}
            />
        </Dialog>
    );
};
