import { AxiosPromise } from 'axios';

import axiosFetch from './axiosFetch';

import { THistoryResp } from '../interfaces/history';
import { IIssue } from '../interfaces/issues';

export default class HistoryAPI {
    /* Получение истории изменений заявки */
    getHistory(issueId: IIssue['id']) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/history/${issueId}/history`,
            isAuthorization: true,
        }) as AxiosPromise<THistoryResp>;
    }

    getHistoryAndComments(issueId: string, params?: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/issues/${issueId}/comments_and_history`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
