import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { Button, Tab, Tabs, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';

import { Header, ModalWrapper, TitleWrapper } from './styles';

import { fetchCompany, setCompany } from '../store';
import { Container } from '../styles';

export const CompanyPage = () => {
    const params = useParams<{ companyId: string }>();
    const location = useLocation();

    const { company } = useSelector(companiesPartnersSelector);

    const dispatch = useAppDispatch();

    const getSelectedTab = () => {
        if (location.pathname.match(ERoutes.CompanyMain)) return 0;
        if (location.pathname.match(ERoutes.CompanyUsers)) return 1;
    };

    useEffect(() => {
        if (!params.companyId) return;

        dispatch(fetchCompany(params?.companyId));

        return () => {
            dispatch(setCompany(null));
        };
    }, []);

    return (
        <MainLayout>
            <Container>
                <TitleWrapper>
                    <Typography
                        fontWeight={700}
                        sx={{ fontSize: '28px' }}
                    >
                        {company?.name}
                    </Typography>
                    <Button
                        sx={{ color: '#0040F1' }}
                        component={Link}
                        to={`/${ERoutes.Companies}/`}
                    >
                        Назад
                    </Button>
                </TitleWrapper>
                <ModalWrapper>
                    <Header>
                        <Tabs value={getSelectedTab()}>
                            <Link to={ERoutes.CompanyMain}>
                                <Tab
                                    value={0}
                                    label="Основное"
                                />
                            </Link>
                            <Link to={ERoutes.CompanyUsers}>
                                <Tab
                                    value={1}
                                    label="Пользователи"
                                />
                            </Link>
                        </Tabs>
                    </Header>
                    <Outlet />
                </ModalWrapper>
            </Container>
        </MainLayout>
    );
};
