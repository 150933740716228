import { companiesPartnersApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';
import { ICompany } from 'interfaces/companies';

import { TFilters, TState, TUser } from './types';

export const initialState: TState = {
    companies: [],
    company: null,

    user: null,

    filters: {
        search: '',
        partner_id: '',
        pk__in: [],
        order_by: [],
    },

    loading: false,
};

const slice = createSlice({
    name: 'companiesPartners',
    initialState,
    reducers: (create) => ({
        fetchCompanies: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { filters } = (getState() as RootState).companiesPartners;

                const params = getFilledParams({
                    ...filters,
                });

                const { data } = await companiesPartnersApi.getCompanies(params);

                dispatch(setCompanies(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        fetchCompany: create.asyncThunk<void, string>(
            async (id: string, { dispatch }) => {
                const { data } = await companiesPartnersApi.getCompany(id);

                dispatch(setCompany(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setCompanies: create.reducer((state, { payload }: PayloadAction<ICompany[]>) => {
            state.companies = payload;
        }),

        setCompany: create.reducer((state, { payload }: PayloadAction<ICompany | null>) => {
            state.company = payload;
        }),

        setUser: create.reducer((state, { payload }: PayloadAction<TUser>) => {
            state.user = payload;
        }),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.filters = { ...state.filters, ...(payload as any) };
        }),
    }),
});

export const { setFilters, fetchCompanies, fetchCompany, setCompanies, setCompany, setUser } = slice.actions;

export default slice.reducer;
