import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { fetchCompanies, setFilters } from 'pages/companies/store';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';

import { Search } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, InputAdornment, TextField } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { ControlsWrapper, FiltersWrapper, ListItem, StyledButton, StyledPopover } from './styles';

import { CreateCompany } from '../modals/create-company';

const sortItemsMapped: Record<string, string> = {
    name: '↓ Наименование',
    '-name': '↑ Наименование',
};

export const Controls = () => {
    const dispatch = useAppDispatch();

    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);
    const { filters } = useSelector(companiesPartnersSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState<any>(false);

    const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(setFilters({ [e.target.name]: e.target.value }));
        dispatch(fetchCompanies());
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    const handleChangeSort = (value: string) => {
        dispatch(setFilters({ order_by: [value] }));
        dispatch(fetchCompanies());
        setIsSortOpen(false);
    };

    const handleClearSort = () => {
        dispatch(setFilters({ order_by: [] }));
        dispatch(fetchCompanies());
        setIsSortOpen(false);
    };

    const isSuperAdmin = profile?.is_superadmin;
    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <ControlsWrapper>
            <FiltersWrapper>
                <TextField
                    name="search"
                    placeholder="Поиск по наименованию"
                    variant="outlined"
                    size="small"
                    onChange={handleSearchDebounced}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={filters?.search}
                />
                <TextField
                    name="pk__in"
                    placeholder="Поиск по id компании"
                    variant="outlined"
                    size="small"
                    onChange={handleSearchDebounced}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={filters?.pk__in}
                />
                <TextField
                    name="partner_id"
                    placeholder="Поиск по id партнера"
                    variant="outlined"
                    size="small"
                    onChange={handleSearchDebounced}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    defaultValue={filters?.partner_id}
                />
                <Button
                    variant="filter"
                    onClick={(e) => setIsSortOpen(e.currentTarget)}
                    endIcon={<ExpandMoreIcon />}
                >
                    {filters?.order_by?.[0] ? sortItemsMapped[filters?.order_by?.[0]] : 'Сортировка'}
                </Button>
                <StyledPopover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(isSortOpen)}
                    anchorEl={isSortOpen}
                    onClose={() => setIsSortOpen(false)}
                >
                    <div>
                        {Object.entries(sortItemsMapped).map(([value, title]) => (
                            <ListItem
                                sx={{ cursor: 'pointer' }}
                                key={value}
                                onClick={() => handleChangeSort(value as string)}
                            >
                                {title}
                                {filters?.order_by?.[0] === value ? <CheckIcon /> : null}
                            </ListItem>
                        ))}
                    </div>
                    <StyledButton onClick={handleClearSort}>Очистить сортировку</StyledButton>
                </StyledPopover>
            </FiltersWrapper>

            {isSuperAdmin || isOwner || isCompanyOwner ? (
                <Button
                    variant="contained"
                    onClick={() => setIsOpen(true)}
                >
                    Создать компанию
                </Button>
            ) : null}

            <CreateCompany
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />
        </ControlsWrapper>
    );
};
