import { Dialog, styled } from '@mui/material';

export const MuiDialog = styled(Dialog)({
    '.MuiDialog-paper': {
        width: '400px',
        borderRadius: '8px',
    },
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    marginTop: '20px',
});
