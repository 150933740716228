import { EIssueHelpType } from 'enums/issues.enum';
import { TPriceListService, TPriceListServiceFilters } from 'pages/price-lists/store/types';
import { AppDispatch } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { pricesApi, servicesPriceListsApi } from '../../api';
import { EPricesActions } from '../../enums/actions.enum';
import { EPricesTabs } from '../../enums/prices.enum';
import { IPagination } from '../../interfaces/pagination';
import {
    IPrice,
    IPricesFilters,
    ISetIsOpenDeletePriceAction,
    ISetIsPriceFileLoadingAction,
    ISetIsPriceLoadingAction,
    ISetPaginationAction,
    ISetPriceAction,
    ISetPricesAction,
    ISetPricesFiltersAction,
    ISetPricesTabAction,
} from '../../interfaces/prices';
import { getQueryParamsAsString, TArrayParams } from '../../utils/helpers/getQueryParamsAsString';

export const setPricesAction = (payload: IPrice[] | TPriceListService[] | null): ISetPricesAction => ({
    type: EPricesActions.SetPrices,
    payload,
});

export const setPriceAction = (payload: IPrice | null): ISetPriceAction => ({
    type: EPricesActions.SetPrice,
    payload,
});

export const setPricesFiltersAction = (payload: IPricesFilters | null): ISetPricesFiltersAction => ({
    type: EPricesActions.SetFilters,
    payload,
});

export const setIsPriceFileLoadingAction = (payload: boolean): ISetIsPriceFileLoadingAction => ({
    type: EPricesActions.SetIsPriceFileLoading,
    payload,
});

export const setPricesTabAction = (payload: EPricesTabs): ISetPricesTabAction => ({
    type: EPricesActions.SetPricesTab,
    payload,
});

export const setPaginationAction = (payload: IPagination): ISetPaginationAction => ({
    type: EPricesActions.SetPagination,
    payload,
});

export const setIsPriceLoadingAction = (payload: boolean): ISetIsPriceLoadingAction => ({
    type: EPricesActions.SetIsPriceLoading,
    payload,
});

export const setIsOpenDeletePriceAction = (payload: boolean): ISetIsOpenDeletePriceAction => ({
    type: EPricesActions.SetIsOpenDeletePrice,
    payload,
});

interface IGetPricesThunkParams {
    filters?: IPricesFilters | null;
    page?: IPagination['page'];
    size?: IPagination['size'];
}

export const getPricesThunk =
    ({ filters, page, size }: IGetPricesThunkParams) =>
    async (dispatch: AppDispatch) => {
        try {
            const queriesObj: TArrayParams = [];

            if (filters) {
                const {
                    service_id__in,
                    ratio_category_id,
                    partner_name,
                    partner_id,
                    help_type__in,
                    region,
                    feature_type,
                    search,
                    category_id__in,
                    with_additional,
                    is_additional,
                    is_active_on_widget,
                    with_paginate,
                } = filters;

                if (service_id__in) {
                    queriesObj.push({ service_id__in });
                }

                if (ratio_category_id) {
                    queriesObj.push({ ratio_category_id });
                }

                if (partner_name) {
                    queriesObj.push({ partner_name });
                }

                if (partner_id) {
                    queriesObj.push({ partner_id });
                }

                if (help_type__in) {
                    queriesObj.push({ help_type__in });
                }

                if (region) {
                    queriesObj.push({ region });
                }

                if (feature_type) {
                    queriesObj.push({ feature_type });
                }

                if (search) {
                    queriesObj.push({ search });
                }

                if (with_additional !== null) {
                    queriesObj.push({ with_additional });
                }

                if (with_paginate !== null) {
                    queriesObj.push({ with_paginate });

                    if (with_paginate) {
                        queriesObj.push({ page: page ? page + 1 : 1 });
                        queriesObj.push({ size: size || 50 });
                    }
                }

                if (is_additional !== null) {
                    queriesObj.push({ is_additional });
                }

                if (is_active_on_widget !== null) {
                    queriesObj.push({ is_active_on_widget });
                }

                if (category_id__in) {
                    category_id__in.forEach((categoryId) => {
                        queriesObj.push({ category_id__in: categoryId });
                    });
                }
            }

            const queries = getQueryParamsAsString(queriesObj);

            //TODO: врменно пока на бэке не разобрались с пагинацией
            if (!filters?.with_paginate) {
                const { data } = await pricesApi.getPricesWithOutPagination(queries);
                // console.log(data);
                dispatch(setPricesAction(data));
                return;
            }

            const { data } = await pricesApi.getPrices(queries);

            const { items: prices, page: pageResp, size: sizeResp, total } = data;
            dispatch(setPricesAction(prices));
            dispatch(setPaginationAction({ page: pageResp - 1, size: sizeResp, total }));
        } catch (err) {
            console.error(err);
            throw err;
        }
    };

export const getPriceListServiceThunk = (filters: TPriceListServiceFilters) => async (dispatch: AppDispatch) => {
    try {
        const {
            company_id,
            category_id__in,
            region,
            help_type,
            search,
            is_active,
            price_list__is_active,
            feature_type,
            access_type__in,
        } = filters;

        const params: Record<string, any> = {
            with_paginate: false,
            feature_type,
            search,
            company_id,
            help_type,
            region,

            category_id__in,
            access_type__in,
            is_active,
            price_list__is_active,
        };

        if (help_type === EIssueHelpType.Repair) {
            params.with_general_price_list = true;
        }

        if (help_type === EIssueHelpType.Installation) {
            params.with_general_price_list = false;
        }

        const { data } = await servicesPriceListsApi.getServicesComplexWithOutPaginate(getFilledParams(params));
        dispatch(setPricesAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};

export const getPriceThunk = (priceId: string) => async (dispatch: AppDispatch) => {
    try {
        const { data } = await pricesApi.getPrice(priceId);
        dispatch(setPriceAction(data));
    } catch (err) {
        console.error(err);
        throw err;
    }
};
