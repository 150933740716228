import { setDepositIds } from 'pages/accounting/deposits/store';
import { useAppDispatch } from 'store/hooks';

import { Checkbox, FormControlLabel, TableCell, TableHead, TableRow, styled } from '@mui/material';

export const Head = ({ ids }: { ids: string[] }) => {
    const dispatch = useAppDispatch();

    const handleChange = (_: any, checked: boolean) => {
        if (checked) {
            dispatch(setDepositIds(ids));
        } else {
            dispatch(setDepositIds([]));
        }
    };

    return (
        <TableHead>
            <TableRow>
                <Cell>
                    <Label
                        onChange={handleChange}
                        control={<Checkbox />}
                        label={'ЗАЯВКА'}
                    />
                </Cell>
                <Cell>ПАРТНЕР</Cell>
                <Cell>ТИП</Cell>
                <Cell>МАСТЕР</Cell>
                <Cell>СОЗДАНА</Cell>
                <Cell>ЗАКРЫТА</Cell>
                <Cell>ОПЛАТА</Cell>
                <Cell>СУММА</Cell>
                <Cell>КОМИССИЯ</Cell>
                <Cell>СТАТУС</Cell>
                <Cell />
            </TableRow>
        </TableHead>
    );
};

const Cell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});

const Label = styled(FormControlLabel)({
    margin: '0',
    '.MuiTypography-root': {
        color: '#9E9E9E',
        fontWeight: '600',
        fontSize: '12px',
    },
});
