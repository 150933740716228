import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';

import { ButtonsContainer, CloseIconWrapper, SubTitle } from '../styles';

type Props = {
    isOpenDialog: boolean;
    setOpenDialog: (value: boolean) => void;
    addComment: (value: string) => void;
};

export const AddComment: FC<Props> = ({ isOpenDialog, setOpenDialog, addComment }) => {
    const [value, setValue] = useState<string>('');

    return (
        <Dialog
            open={isOpenDialog}
            sx={{
                '.MuiDialog-paper': {
                    width: '480px',
                    borderRadius: '8px',
                    position: 'relative',
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Создать комментарий</Typography>
                <CloseIconWrapper
                    onClick={() => {
                        setOpenDialog(false);
                        setValue('');
                    }}
                >
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                <SubTitle>Данный комментарий увидят только пользователи админки</SubTitle>
                <TextField
                    sx={{ width: '100%', marginTop: '15px' }}
                    multiline
                    minRows={3}
                    placeholder="Комментарий"
                    onChange={(e) => setValue(e.target.value)}
                />
                <ButtonsContainer>
                    <Button
                        onClick={() => {
                            setOpenDialog(false);
                            setValue('');
                        }}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={() => {
                            addComment(value);
                            setValue('');
                        }}
                        variant="contained"
                        disabled={!value}
                    >
                        Отправить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
