import { CloseIcon } from 'static/Icons/CloseIcon';

import { Button, Dialog, DialogTitle, Typography, DialogContent } from '@mui/material';
import { FC } from 'react';

import { TitleWrapper, CloseIconWrapper, ButtonsContainer } from './styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    onClickDeleteButton: (index: number) => void;
    idx: number | null;
};

export const ConfirmDelete: FC<Props> = ({ isOpen, setIsOpen, onClickDeleteButton, idx }) => {
    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                <TitleWrapper>
                    <Typography variant="h5">Подтвердите действие</Typography>
                    <CloseIconWrapper onClick={() => setIsOpen(false)}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </TitleWrapper>
            </DialogTitle>
            <DialogContent>
                <Typography>Вы действительно хотите удалить услугу ?</Typography>
                <ButtonsContainer>
                    <Button
                        onClick={() => {
                            onClickDeleteButton(idx as number);
                            setIsOpen(false);
                        }}
                        variant="contained"
                    >
                        Да
                    </Button>
                    <Button
                        onClick={() => setIsOpen(false)}
                        variant="outlined"
                    >
                        Нет
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
