import Loader from 'components/Loader';
import { Switch } from 'components/Switch';
import { withWrapper } from 'hocs/with-wrapper';
import { depositsSelector } from 'pages/accounting/deposits/store/selectors';
import { TPrice } from 'pages/accounting/deposits/store/types';
import { calculatePercentage } from 'utils/helpers/calculatePercentage';

import {
    Table as MuiTable,
    styled,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    TextField,
    InputBaseComponentProps,
} from '@mui/material';
import { FC, useRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

type Props = {
    rows: TPrice[];
    loading: boolean;
    onPercentageChange: (id: string, value: string) => void;
    onPercentChange: (id: string, value: number) => void;
    onAmountChange: (id: string, value: number) => void;
    partnerName: string;
};

export const Table: FC<Props> = ({
    rows,
    loading,
    onPercentageChange,
    onPercentChange,
    onAmountChange,
    partnerName,
}) => {
    const { deposit } = useSelector(depositsSelector);

    const percentRefs = useRef<Record<string, HTMLInputElement>>({});
    const fixationRefs = useRef<Record<string, HTMLInputElement>>({});

    const percentRef = useRef<HTMLInputElement>(null);
    const fixationRef = useRef<HTMLInputElement>(null);

    if (loading) return withWrapper(Wrapper, Loader);

    return (
        <MuiTable
            sx={{
                display: 'grid',
                gridTemplateColumns: '100px 130px 120px 1fr 120px 120px 140px 130px 140px 110px',
                gridAutoFlow: 'row',
                width: '100%',
            }}
        >
            <TableHead sx={{ display: 'contents' }}>
                <TableRow sx={{ display: 'contents' }}>
                    <HeadCell sx={{ paddingLeft: '0' }}>ПЛАТЕЛЬЩИК</HeadCell>
                    <HeadCell align="center">ПАРТНЕР</HeadCell>
                    <HeadCell align="center">ТИП УСЛУГИ</HeadCell>
                    <HeadCell align="center">НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                    <HeadCell align="center">ЦЕНА</HeadCell>
                    <HeadCell align="center">КОЛИЧЕСТВО</HeadCell>
                    <HeadCell align="center">СУММА</HeadCell>
                    <HeadCell align="center">ПРОЦЕНТ</HeadCell>
                    <HeadCell align="center">КОМИССИЯ</HeadCell>
                    <HeadCell sx={{ paddingRight: '0' }} />
                </TableRow>
            </TableHead>
            <TableBody sx={{ display: 'contents' }}>
                {rows.map((row) => {
                    const isPercantage = row.commission_type === 'percentage';

                    return (
                        <TableRow
                            sx={{ display: 'contents' }}
                            key={row.id}
                        >
                            <Cell sx={{ paddingLeft: '0' }}>
                                {!row.price_recipient ? '-' : row.price_recipient === 'client' ? 'Клиент' : 'Партнер'}
                            </Cell>
                            <Cell align="center">{partnerName}</Cell>
                            <Cell align="center">{row.price.service.name}</Cell>
                            <Cell align="center">{row.price.name}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    disabled={deposit?.status !== 'suggested'}
                                    displayType="text"
                                    value={row?.service_amount_with_discount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">{row?.quantity}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    disabled
                                    displayType="text"
                                    value={row?.total_discount_amount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">
                                {isPercantage ? (
                                    <NumericFormat
                                        disabled={deposit?.status !== 'suggested'}
                                        inputProps={CellInputProps}
                                        thousandSeparator=" "
                                        value={row.commission_amount * 100}
                                        customInput={TextField}
                                        suffix=" %"
                                        getInputRef={(ref: HTMLInputElement) => {
                                            if (percentRefs.current[row.id]) return;

                                            percentRefs.current[row.id] = ref.children[0]
                                                .children[0] as HTMLInputElement;
                                        }}
                                        inputRef={percentRef}
                                        onValueChange={(e) => {
                                            if (!e.floatValue) percentRefs.current?.[row.id]?.select();
                                            onPercentChange(row.id, +e.value / 100);
                                        }}
                                        isAllowed={(values) => {
                                            const { value, floatValue } = values;

                                            return (
                                                value === '-' ||
                                                value === '' ||
                                                (floatValue! <= 100 && floatValue! >= -100)
                                            );
                                        }}
                                        decimalScale={0}
                                    />
                                ) : (
                                    <span>
                                        {calculatePercentage(row?.commission_amount, row?.total_discount_amount)}
                                    </span>
                                )}
                            </Cell>
                            <Cell align="center">
                                {isPercantage ? (
                                    <span>
                                        <NumericFormat
                                            displayType="text"
                                            value={row.total_discount_amount * row.commission_amount}
                                            suffix=" ₽"
                                            thousandSeparator=" "
                                            decimalScale={2}
                                        />
                                    </span>
                                ) : (
                                    <NumericFormat
                                        thousandSeparator=" "
                                        inputProps={CellInputProps}
                                        value={row.commission_amount}
                                        getInputRef={(ref: HTMLInputElement) => {
                                            if (!fixationRefs.current[row.id]) return;

                                            fixationRefs.current[row.id] = ref.children[0]
                                                .children[0] as HTMLInputElement;
                                        }}
                                        inputRef={fixationRef}
                                        customInput={TextField}
                                        suffix=" ₽"
                                        onValueChange={(e) => {
                                            if (!e.floatValue) fixationRefs.current?.[row.id]?.select();
                                            onAmountChange(row.id, +e.value);
                                        }}
                                        decimalScale={2}
                                        error={row.commission_amount > row.amount}
                                    />
                                )}
                            </Cell>
                            <Cell
                                align="center"
                                sx={{ paddingRight: '0' }}
                            >
                                %
                                <Switch
                                    sx={{ m: 1 }}
                                    disabled={deposit?.status !== 'suggested'}
                                    defaultChecked={!isPercantage}
                                    onChange={(e) => {
                                        const value = e.target.checked ? 'fixation' : 'percentage';
                                        onPercentageChange(row.id, value);
                                    }}
                                />
                                ₽
                            </Cell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};

const Wrapper = styled('div')({
    position: 'relative',
    padding: '100px 0',
});

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});

const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};

const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
});
