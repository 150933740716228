import { companiesApi, priceListsApi, servicesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { Container } from 'pages/services/styles';
import { ActiveRadioChecked } from 'static/Icons/ActiveRadioChecked';
import { InactiveRadioChecked } from 'static/Icons/InactiveRadioChecked';
import { Option, selectMapper, regionsMapper } from 'utils/helpers/selectMapper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Checkbox, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    ButtonsContainer,
    CheckboxContainer,
    CheckboxItem,
    Field,
    FieldsContainer,
    ImportFile,
    StyledPaper,
    SubTitle,
} from './styles';

export const CreatePriceListsPage = () => {
    const navigate = useNavigate();

    const fileInputRef = useRef<HTMLInputElement>(null);

    const [form, setForm] = useState({
        name: '',
        system_name: '',
        company_id: '',
        service_type_id: '',
        region_id: null,
        services: {},
    });

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const [checkValue, setCheckValue] = useState<string>('default');
    const [fileId, setFileId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>('');

    const isCreateDisabled =
        isLoading ||
        checkValue === 'default' ||
        !form.name ||
        !form.system_name ||
        !form.company_id ||
        !form.service_type_id ||
        (checkValue === 'importFile' && !fileId);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setForm((prev) => {
            const updatedForm = {
                ...prev,
                [e.target.name]: e.target.value,
            };

            const condition =
                updatedForm.company_id &&
                updatedForm.service_type_id &&
                (e.target.name === 'company_id' ||
                    e.target.name === 'service_type_id' ||
                    e.target.name === 'region_id');

            const regionCondition =
                updatedForm.region_id &&
                (e.target.name === 'company_id' ||
                    e.target.name === 'service_type_id' ||
                    e.target.name === 'region_id');

            if (condition) {
                updatedForm.system_name = `${
                    companies.find((company) => company.value === updatedForm.company_id)?.label
                }. ${serviceTypes.find((service) => service.value === updatedForm.service_type_id)?.label}${
                    regionCondition
                        ? `. ${regions.find((region) => region.value === updatedForm.region_id)?.label}`
                        : ''
                }`;
            }

            return updatedForm;
        });
    };

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        setCompanies(companiesRes.data.map(selectMapper));
        setServiceTypes(serviceTypesRes.data.map(selectMapper));
        setRegions(regionsRes.data.items.map(regionsMapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const handleSearch = async (value: string) => {
        const { data } = await priceListsApi.getRegionality({ search: value });
        setRegions(data.items.map(regionsMapper));
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    const handleDownloadFile = async () => {
        try {
            const fileName = serviceTypes.find((item) => item.value === form.service_type_id)?.label || 'template';
            const { data } = await priceListsApi.downloadTemplate(form.service_type_id, form?.company_id);
            const blob = new Blob([data], {
                type: 'application/vnd.ms-excel',
            });
            const urlBlob = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${companyName} ${fileName}.xlsx`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(urlBlob);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    const handleUploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const formData = new FormData();
            formData.append('file', files[0]);

            try {
                const { data } = await priceListsApi.importServices(formData, form?.company_id);
                console.log(data);

                setFileId(data.file_id);
                enqueueSnackbar('Файл успешно загружен', { variant: 'success' });
            } catch (error: any) {
                enqueueSnackbar(error.response.data.error?.message || error.message, { variant: 'error' });
            } finally {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    const createPriceList = async () => {
        try {
            setIsLoading(true);
            let body = { ...form };
            if (checkValue === 'servicesMatchType') {
                body.services = {
                    add_type: 'same_type',
                };
            } else if (checkValue === 'importFile') {
                body.services = {
                    add_type: 'file',
                    file_id: fileId,
                };
            } else if (checkValue === 'withoutServices') {
                // @ts-ignore
                delete body.services;
            }

            await priceListsApi.createPriceList(body);

            navigate(`/${ERoutes.PriceLists}/`);
            enqueueSnackbar('Прайс-лист успешно создан', { variant: 'success' });
        } catch (error: any) {
            enqueueSnackbar(error.response.data.error.message || error.message, { variant: 'error' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MainLayout>
            <Container>
                <Typography
                    fontWeight={700}
                    sx={{ fontSize: '28px' }}
                >
                    Создать прайс-лист
                </Typography>
                <StyledPaper>
                    <div>
                        <FieldsContainer>
                            <Field>
                                <SubTitle>Компания</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={companies}
                                    onChange={(_, option) => {
                                        setCompanyName(option?.label || '');

                                        handleChange({
                                            target: { name: 'company_id', value: option?.value || '' },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Тип услуги</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={serviceTypes}
                                    onChange={(_, option) => {
                                        handleChange({
                                            target: { name: 'service_type_id', value: option?.value || '' },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Регион</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    options={regions}
                                    onChangeCapture={({ target }) => {
                                        const { value } = target as EventTarget & HTMLInputElement;

                                        handleSearchDebounced(value);
                                    }}
                                    onChange={(_, option) => {
                                        handleChange({
                                            target: { name: 'region_id', value: option?.value || null },
                                        } as ChangeEvent<HTMLInputElement>);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Системное наименование</SubTitle>
                                <TextField
                                    value={form.system_name}
                                    name="system_name"
                                    onChange={handleChange}
                                    sx={{ width: '392px' }}
                                    size="small"
                                />
                            </Field>
                            <Field>
                                <SubTitle>Наименование</SubTitle>
                                <TextField
                                    name="name"
                                    onChange={handleChange}
                                    sx={{ width: '392px' }}
                                    size="small"
                                />
                            </Field>
                        </FieldsContainer>
                        <CheckboxContainer>
                            <Typography sx={{ marginTop: '30px', fontSize: '20px', fontWeight: 600 }}>
                                Варианты создания
                            </Typography>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'withoutServices'}
                                    onClick={() => {
                                        setCheckValue('withoutServices');
                                        setForm((prev) => {
                                            return {
                                                ...prev,
                                                services: { add_type: '', file_id: '' },
                                            };
                                        });
                                    }}
                                />
                                <span>Создать без услуг</span>
                            </CheckboxItem>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'same_type'}
                                    onClick={() => {
                                        setCheckValue('same_type');
                                        setForm((prev) => {
                                            return {
                                                ...prev,
                                                services: { add_type: 'same_type' },
                                            };
                                        });
                                    }}
                                />
                                <span>Создать с услугами, которые подходят по типу</span>
                            </CheckboxItem>
                            <CheckboxItem>
                                <Checkbox
                                    sx={{ padding: 0 }}
                                    icon={<InactiveRadioChecked />}
                                    checkedIcon={<ActiveRadioChecked />}
                                    checked={checkValue === 'importFile'}
                                    onClick={() => {
                                        setCheckValue('importFile');
                                        setForm((prev) => {
                                            return {
                                                ...prev,
                                                services: { add_type: 'file', file_id: '' },
                                            };
                                        });
                                    }}
                                />
                                <span>Импорт услуг из файла</span>
                                {checkValue === 'importFile' ? (
                                    <ImportFile>
                                        <div>
                                            <div>
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    accept=".xlsx"
                                                    name="file"
                                                    style={{ display: 'none' }}
                                                    onChange={handleUploadFile}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    disabled={!form.service_type_id || !form.company_id}
                                                    onClick={() => fileInputRef.current!.click()}
                                                >
                                                    Загрузить файл
                                                </Button>
                                                <Button
                                                    sx={{ color: '#0040F1' }}
                                                    disabled={!form.service_type_id || !form.company_id}
                                                    onClick={handleDownloadFile}
                                                >
                                                    Скачать шаблон
                                                </Button>
                                            </div>
                                        </div>
                                        <span style={{ color: '#BCBFD1', fontSize: '12px', marginTop: '8px' }}>
                                            Поддерживаемые форматы: XLSX
                                        </span>
                                    </ImportFile>
                                ) : null}
                            </CheckboxItem>
                        </CheckboxContainer>
                    </div>
                    <ButtonsContainer>
                        <LoadingButton
                            variant="contained"
                            onClick={createPriceList}
                            disabled={isCreateDisabled}
                            loading={isLoading}
                        >
                            Создать
                        </LoadingButton>
                        <Button
                            variant="outlined"
                            component={Link}
                            to={`/${ERoutes.PriceLists}/`}
                        >
                            Отмена
                        </Button>
                    </ButtonsContainer>
                </StyledPaper>
            </Container>
        </MainLayout>
    );
};
