import { issuesApi } from 'api';
import { Button } from 'components/Button';
import Loader from 'components/Loader';
import { depositStatusesMock } from 'mockData/accounting';
import { fetchDeposits, setDepositIds } from 'pages/accounting/deposits/store';
import { depositsSelector } from 'pages/accounting/deposits/store/selectors';
import { TDeposit } from 'pages/accounting/deposits/store/types';
import { useAppDispatch } from 'store/hooks';

import { ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { Table } from './table';

type Props = {
    open: boolean;
    onClose: () => void;
};

export const AccrualsModal: FC<Props> = ({ open, onClose }) => {
    const dispatch = useAppDispatch();

    const { depositIds } = useSelector(depositsSelector);

    const [loading, setLoading] = useState(true);
    const [sendLoading, setSendLoading] = useState(false);

    const [deposits, setDeposits] = useState<TDeposit[]>([]);

    const fetchDetailDeposits = async () => {
        const promises = depositIds.map((id) => issuesApi.getDeposit(id).then(({ data }) => data));

        const data = await Promise.all(promises).finally(() => {
            setLoading(false);
        });

        setDeposits(data);
    };

    const handleSend = () => {
        setSendLoading(true);

        const data = deposits.map((item) => ({
            deposit_request_id: item.id,
            worker_id: item.worker.id,
            commission_prices: item.prices_suggested.map((price) => ({
                commission_amount: price.commission_amount.toFixed(2),
                commission_type: price.commission_type,
                issue_price_id: price.id,
            })),
        }));

        issuesApi
            .postDeposits({ items: data })
            .then(() => {
                onClose();
                enqueueSnackbar('Начисление успешно выполнено', { variant: 'success' });
                dispatch(fetchDeposits());
                dispatch(setDepositIds([]));
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => setSendLoading(false));
    };

    useEffect(() => {
        setLoading(true);
        setDeposits([]);

        if (!open) return;

        fetchDetailDeposits();
    }, [open]);

    const hideButton = deposits?.find((deposit) => deposit?.status !== 'suggested');

    return (
        <Dialog
            transitionDuration={{ exit: 0, enter: 200 }}
            fullWidth
            open={open}
            sx={{
                '.MuiDialog-paper': {
                    width: '100%',
                    maxWidth: '1800px',
                },
            }}
        >
            <Title>Начисление для выбранных</Title>
            <CloseButton onClick={onClose}>
                <CloseIcon />
            </CloseButton>
            <DialogContent>
                {loading ? (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                ) : (
                    deposits.map((item) => {
                        return (
                            <Item
                                defaultExpanded
                                key={item.id}
                            >
                                <Summary expandIcon={<ExpandMore />}>
                                    <span>
                                        {`${item.worker.last_name || ''} ${item.worker.first_name || ''} ${
                                            item.worker.middle_name || ''
                                        }`}{' '}
                                        {`${
                                            item?.status && item?.status !== 'suggested'
                                                ? `(${depositStatusesMock[item?.status]})`
                                                : ''
                                        }`}
                                    </span>
                                    <span>
                                        <NumericFormat
                                            displayType="text"
                                            value={item.to_deposit_amount}
                                            suffix=" ₽"
                                            thousandSeparator=" "
                                        />
                                    </span>
                                </Summary>
                                <AccordionDetails>
                                    <Table
                                        rows={
                                            item?.status === 'accepted' ? item.prices_accepted : item.prices_suggested
                                        }
                                        issueOid={item.issue.oid}
                                        issueId={item.issue.id}
                                        issueCompanyName={item.issue.company?.name}
                                    />
                                </AccordionDetails>
                            </Item>
                        );
                    })
                )}
            </DialogContent>
            <DialogActions sx={{ padding: '20px 24px' }}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                >
                    Отмена
                </Button>
                {hideButton ? null : (
                    <Button
                        loading={sendLoading}
                        variant="contained"
                        onClick={handleSend}
                    >
                        Начислить
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

const LoaderWrapper = styled('div')({
    position: 'relative',
    padding: '100px 0',
});

const Title = styled(DialogTitle)({
    fontSize: '24px',
    fontWeight: 600,
});

const CloseButton = styled(IconButton)({
    position: 'absolute',
    right: 8,
    top: 8,
    color: `${(theme: any) => theme.palette.grey[500]}`,
});

const Item = styled(Accordion)({
    boxShadow: 'none',
    border: 'none',
    borderTop: '1px solid #00000012 !important',
    margin: '0 !important',
    '.MuiAccordionDetails-root': {
        padding: 0,
    },
    ':before': {
        display: 'none',
    },
    ':last-child': {
        borderBottom: '1px solid #00000012 !important',
    },
    '.MuiTableCell-root': {
        borderTop: '1px solid #00000012 !important',
        borderBottom: 'none',
    },
});

const Summary = styled(AccordionSummary)({
    minHeight: 'auto !important',
    padding: '0 2px',
    boxShadow: 'none',
    '.Mui-expanded': {
        margin: '12px 0 !important',
    },
    '.MuiAccordionSummary-content': {
        justifyContent: 'space-between',
        paddingRight: '15px',
    },
});
