import { companiesPartnersApi } from 'api';
import { fetchCompanies } from 'pages/companies/store';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';

import { ButtonsContainer } from './styles';

import { CloseIconWrapper } from '../styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

type TForm = {
    service_types: [];
    name: string;
};

const serviceTypesOptions = [
    { label: 'Выбрать все', value: 'all' },
    { label: 'Установка', value: 'installation' },
    { label: 'Коммерческий ремонт', value: 'repair' },
    { label: 'Гарантийный ремонт', value: 'warranty_repair' },
];

const initialState: TForm = { service_types: [], name: '' };

export const CreateCompany: FC<Props> = ({ isOpen, setIsOpen }) => {
    const dispatch = useAppDispatch();

    const [form, setForm] = useState<TForm>(initialState);
    const [activeOptions, setActiveOptions] = useState<any>([]);

    const handleCreateCompany = () => {
        companiesPartnersApi
            .createCompany(form)
            .then(() => {
                dispatch(fetchCompanies());
                setIsOpen(false);
                setForm(initialState);
                setActiveOptions([]);

                enqueueSnackbar('Компания создана', {
                    variant: 'success',
                });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handelClose = () => {
        setForm(initialState);
        setActiveOptions([]);
        setIsOpen(false);
    };

    return (
        <Dialog
            fullWidth
            open={isOpen}
        >
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h5">Создать компанию</Typography>
                    <CloseIconWrapper onClick={handelClose}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <TextField
                        sx={{ marginTop: '20px' }}
                        fullWidth
                        label="Название партнера"
                        onChange={(e) => {
                            e.target.value;
                            setForm((prev: any) => {
                                return { ...prev, name: e.target.value };
                            });
                        }}
                    />
                    <Autocomplete
                        sx={{ marginTop: '20px' }}
                        multiple
                        popupIcon={<ExpandMoreIcon />}
                        options={serviceTypesOptions}
                        isOptionEqualToValue={(option, value) => option === value}
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    placeholder="Тип услуги"
                                />
                            </>
                        )}
                        filterSelectedOptions
                        value={activeOptions}
                        onChange={(_, option, reason, details) => {
                            const service_types = option?.map((item) => item.value);

                            if (reason === 'clear') {
                                setActiveOptions(option);
                                setForm((prev: any) => {
                                    return { ...prev, service_types: [] };
                                });
                            } else {
                                if (details?.option?.value === 'all') {
                                    setActiveOptions(serviceTypesOptions.slice(1));
                                    setForm((prev: any) => {
                                        return {
                                            ...prev,
                                            service_types:
                                                reason === 'removeOption'
                                                    ? []
                                                    : ['installation', 'repair', 'warranty_repair'],
                                        };
                                    });
                                } else {
                                    setActiveOptions(option);
                                    setForm((prev: any) => {
                                        return { ...prev, service_types };
                                    });
                                }
                            }
                        }}
                    />
                </Box>
                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        onClick={handelClose}
                    >
                        Отмена
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleCreateCompany}
                        disabled={!form?.name || !form?.service_types?.length}
                    >
                        Сохранить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
