import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ERoutes } from 'enums/routes.enum';
import { Accounting } from 'pages/accounting';
import { Deposits } from 'pages/accounting/deposits';
import { Transactions } from 'pages/accounting/transactions';
import { Withdraws } from 'pages/accounting/withdraws';
import ArchivePage from 'pages/archive';
import { CompaniesPage } from 'pages/companies';
import { CompanyPage } from 'pages/companies/company';
import { CompanyMainPage } from 'pages/companies/company/main';
import { CompanyUsersPage } from 'pages/companies/company/users';
import ExternalFormPage from 'pages/external-form';
import { ImportIssuesPage } from 'pages/import-gsis';
import IssuePage from 'pages/issue';
import IssuesPage from 'pages/issues';
import MapPage from 'pages/map';
import PricePage from 'pages/price';
import { PriceListsPage } from 'pages/price-lists';
import { CreatePriceListsPage } from 'pages/price-lists/create-price-lists';
import { PriceListPage } from 'pages/price-lists/price-list';
import { PriceListComplexesServicesPage } from 'pages/price-lists/price-list/complexes-services';
import { AddComplexServices } from 'pages/price-lists/price-list/complexes-services/add-complex-services';
import { EditComplexServices } from 'pages/price-lists/price-list/complexes-services/edit-complex-services';
import { PriceListMainPage } from 'pages/price-lists/price-list/main';
import PricesPage from 'pages/prices';
import ServicePage from 'pages/service';
import { ServicesPage } from 'pages/services';
import SupportPage from 'pages/support';
import WorkerPage from 'pages/worker';
import WorkersPage from 'pages/workers';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';

import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';

function MainRoutes() {
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    const isSuperAdmin = profile?.is_superadmin;

    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );
    const isPriceListOwner = profile?.role?.permissions?.[EPermissionsKeys.PriceLists]?.includes(
        EPermissionsValues.Owner,
    );
    const isPriceListCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.PriceLists]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <Routes>
            <Route
                path={`${ERoutes.Issues}/`}
                element={<IssuesPage />}
            />

            <Route
                path={`${ERoutes.Issues}/:issueId`}
                element={<IssuePage />}
            />

            <Route
                path={`${ERoutes.Workers}/`}
                element={<WorkersPage />}
            />

            <Route
                path={`${ERoutes.Workers}/:workerId`}
                element={<WorkerPage />}
            />

            <Route
                path={`${ERoutes.Prices}/`}
                element={<PricesPage />}
            />

            <Route
                path={`${ERoutes.PriceServices}/:serviceId`}
                element={<ServicePage />}
            />

            <Route
                path={`${ERoutes.Price}/:priceId`}
                element={<PricePage />}
            />

            <Route
                path={`${ERoutes.Map}/`}
                element={<MapPage />}
            />

            <Route
                path={`${ERoutes.Test1}/`}
                element={<IssuesPage />}
            />

            <Route
                path={`${ERoutes.Support}/`}
                element={<SupportPage />}
            />

            <Route
                path={`${ERoutes.Archive}/`}
                element={<ArchivePage />}
            />

            <Route
                path={`${ERoutes.Archive}/:issueId`}
                element={<IssuePage />}
            />

            <Route
                path={`${ERoutes.Archive}/worker/:workerId`}
                element={<WorkerPage />}
            />

            <Route
                path={`${ERoutes.ExternalForm}/`}
                element={<ExternalFormPage />}
            />

            {isOwner || isCompanyOwner ? (
                <Route
                    path={`${ERoutes.Accounting}/`}
                    element={<Accounting />}
                >
                    <Route
                        path={`${ERoutes.Deposits}`}
                        element={<Deposits />}
                    />
                    <Route
                        path={`${ERoutes.Withdraws}`}
                        element={<Withdraws />}
                    />
                    <Route
                        path={`${ERoutes.Transactions}`}
                        element={<Transactions />}
                    />
                </Route>
            ) : null}

            <Route
                path={`${ERoutes.Companies}`}
                element={<CompaniesPage />}
            />

            <Route
                path={`${ERoutes.Companies}/:companyId/`}
                element={<CompanyPage />}
            >
                <Route
                    path={`${ERoutes.CompanyMain}`}
                    element={<CompanyMainPage />}
                />
                <Route
                    path={`${ERoutes.CompanyUsers}`}
                    element={<CompanyUsersPage />}
                />
            </Route>

            <Route
                path={`${ERoutes.ImportIssues}/:partnerId`}
                element={<ImportIssuesPage />}
            />
            {isPriceListOwner || isPriceListCompanyOwner || isSuperAdmin ? (
                <>
                    <Route
                        path={`${ERoutes.Services}/`}
                        element={<ServicesPage />}
                    />
                    <Route
                        path={`${ERoutes.PriceLists}/`}
                        element={<PriceListsPage />}
                    />

                    <Route
                        path={`${ERoutes.PriceLists}/${ERoutes.CreatePriceLists}`}
                        element={<CreatePriceListsPage />}
                    />

                    <Route
                        path={`${ERoutes.PriceLists}/:priceListId/`}
                        element={<PriceListPage />}
                    >
                        <Route
                            path={`${ERoutes.PriceListMain}`}
                            element={<PriceListMainPage />}
                        />
                        <Route
                            path={`${ERoutes.PriceListComplexServices}`}
                            element={<PriceListComplexesServicesPage />}
                        />
                    </Route>

                    <Route
                        path={`${ERoutes.PriceLists}/:priceListId/${ERoutes.PriceListComplexServices}/${ERoutes.AddComplexServices}`}
                        element={<AddComplexServices />}
                    />

                    <Route
                        path={`${ERoutes.PriceLists}/:priceListId/${ERoutes.PriceListComplexServices}/:serviceComplexId/${ERoutes.EditComplexServices}`}
                        element={<EditComplexServices />}
                    />
                </>
            ) : null}

            <Route
                path="*"
                element={
                    <Navigate
                        replace
                        to={`${ERoutes.Issues}/`}
                    />
                }
            />
        </Routes>
    );
}

export default MainRoutes;
