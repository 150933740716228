import CheckIcon from '@mui/icons-material/Check';
import { Link } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import IssueClientPrices from './IssueClientPrices';
import IssuePartnerPrices from './IssuePartnerPrices';
import PaymentMethod from './PaymentMethod';
import SendForPayment from './SendForPayment';

import Accordion from '../../../../components/Accordion';
import { EIssueStatus } from '../../../../enums/issues.enum';
import { EPermissionsKeys, EPermissionsValues } from '../../../../enums/permissions.enum';
import { issueEditingPaymentMethod, issuePriceEditingAllowedStatuses } from '../../../../mockData/issues';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';
import { profileSelector } from '../../../../store/selectors/profileSelectors';
import { dateFormatter } from '../../../../utils/helpers/dateFormatter';
import { isAccessDenied } from '../../../../utils/helpers/isAccesDenite';
import InvoicingNotifications from '../InvoicingNotifications';
import './IssuePrices.scss';

function IssuePrices() {
    const issue = useSelector(issueSelector);
    const profile = useSelector(profileSelector);

    /* Проверка на возможность редактировать prices */
    const isEditable = useMemo(() => {
        switch (true) {
            case issue?.status === EIssueStatus.Solved:
                return isAccessDenied({
                    profile,
                    entityRole: EPermissionsKeys.Issues,
                    action: EPermissionsValues.SetPriceSolved,
                });
            case issue?.status && issuePriceEditingAllowedStatuses.includes(issue.status):
                return true;
            default:
                return false;
        }
    }, [issue?.status]);

    return issue ? (
        <Accordion
            title="Стоимость и услуги"
            sx={{ mt: 3 }}
        >
            {issue.invoicing_file && (
                <div>
                    <Link
                        target="_blank"
                        href={issue?.invoicing_file?.url}
                    >
                        Акт выполненных работ
                    </Link>
                </div>
            )}
            <div className="iss-prices">
                {issue.status === EIssueStatus.Solved && issue.succeeded_payment && (
                    <div className="payment-card">
                        <CheckIcon
                            className="check-icon"
                            fontSize="small"
                        />
                        <span>Оплата картой</span>
                        <p className="time">
                            {dateFormatter(
                                issue.succeeded_payment.created_at,
                                {},
                                {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                },
                            )}
                        </p>
                    </div>
                )}
                {issue?.price?.details.length && issue.status !== EIssueStatus.Solved && <SendForPayment />}

                {issueEditingPaymentMethod.includes(issue.status) && <PaymentMethod />}

                {/* <div style={{ display: 'none' }}> */}
                <IssueClientPrices
                    price={issue.price || null}
                    isEditable={isEditable}
                />
                {/* </div> */}

                <InvoicingNotifications />

                {issue?.partner && (
                    <IssuePartnerPrices
                        price={issue.price || null}
                        editable={isEditable}
                    />
                )}
            </div>
        </Accordion>
    ) : null;
}

export default memo(IssuePrices);
