import { EIssueHelpType, EIssueStatus } from 'enums/issues.enum';
import useOutsideClick from 'hooks/useOutsideClick';
import { issueStatusesMock } from 'mockData/issues';
import {
    setErrorsEditIssueStatusToCancelAction,
    setFormValuesEditIssueStatusToCancelAction,
    setIsOpenEditIssueStatusToCancelAction,
    setIssueIdEditIssueStatusToCancelAction,
} from 'store/actions/edit/editIssueStatusToCancel';
import { setIsOpenEditIssueStatusToMasterAction } from 'store/actions/edit/editIssueStatusToMasterSearch';
import {
    setDataEditIssueStatusWithCommentAction,
    setErrorsEditIssueStatusWithCommentAction,
    setFormValuesEditIssueStatusWithCommentAction,
    setIsOpenEditIssueStatusWithCommentAction,
    setIssueIdEditIssueStatusWithCommentAction,
} from 'store/actions/edit/editIssueStatusWithComment';
import { useAppDispatch } from 'store/hooks';
import { issueActivePutStatusesSelector, issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import cn from 'classnames';
import { IDataEditIssueStatusWithComment } from 'interfaces/edit/editIssueStatusWithComment';
import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import EditIssueStatusToCancel from './EditIssueStatusToCancel';
import EditIssueStatusToMasterSearchWithoutCoords from './EditIssueStatusToMasterSearchWithoutCoords';
import EditIssueStatusWithComment from './EditIssueStatusWithComment';

import './IssueStatus.scss';

function IssueStatus() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);
    const putStatuses = useSelector(issueActivePutStatusesSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [chosenReason, setChosenReason] = useState<string>();

    const ref = useOutsideClick(() => {
        setIsOpen(false);
    });

    function openChangeStatusToCancelModal() {
        if (!issue) return;
        if (issue.is_archived) return;
        dispatch(setIssueIdEditIssueStatusToCancelAction(issue.id));
        dispatch(setFormValuesEditIssueStatusToCancelAction(null));
        dispatch(setErrorsEditIssueStatusToCancelAction(null));
        dispatch(setIsOpenEditIssueStatusToCancelAction(true));
    }

    function openChangeStatusModal(data: IDataEditIssueStatusWithComment) {
        if (!issue) return;
        if (issue.is_archived) return;

        if (
            !issue.diagnostic?.act_nrp_file &&
            issue.status === EIssueStatus.ActNRP &&
            data.newStatus === EIssueStatus.Solved
        ) {
            defaultErrorCallback({ errorMessage: 'Необходимо загрузить акт НРП', onOkText: '' });
            return;
        }

        dispatch(setIssueIdEditIssueStatusWithCommentAction(issue.id));
        dispatch(setFormValuesEditIssueStatusWithCommentAction(null));
        dispatch(setErrorsEditIssueStatusWithCommentAction(null));
        dispatch(setIsOpenEditIssueStatusWithCommentAction(true));
        dispatch(setDataEditIssueStatusWithCommentAction(data));
        setChosenReason(issueStatusesMock[data.newStatus].title);
    }

    function openChangeStatusToMasterSearchModal() {
        if (!issue) return;
        if (issue.is_archived) return;
        dispatch(setIsOpenEditIssueStatusToMasterAction(true));
    }

    return (
        issue && (
            <div
                className="issue-status-wrapper"
                ref={ref}
            >
                <Box
                    sx={{
                        fontWeight: '600',
                        backgroundColor: issueStatusesMock[issue.status]?.colorHex,
                        color: `${issueStatusesMock[issue.status]?.colorText} !important`,
                    }}
                    className="btn-status"
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {issueStatusesMock[issue.status].title}
                    {!!putStatuses?.length && <ExpandMoreIcon sx={{ rotate: isOpen ? '0deg' : '180deg' }} />}
                </Box>
                {!!putStatuses?.length && (
                    <div className={cn('put-statuses', { isOpen })}>
                        {putStatuses.map((putStatus, index) => {
                            const { title, colorHex, colorText } = issueStatusesMock[putStatus];
                            const name = index.toString();
                            return (
                                <Box
                                    onClick={async () => {
                                        if (putStatus === EIssueStatus.Canceled) {
                                            openChangeStatusToCancelModal();
                                        } else if (putStatus === EIssueStatus.Solved) {
                                            if (!issue?.client?.id) return;
                                            openChangeStatusModal({
                                                newStatus: putStatus,
                                                putSolvedIssueStatusData: { client_id: issue?.client?.id },
                                            });
                                        } else if (putStatus === EIssueStatus.MasterSearch) {
                                            issue?.help_type !== EIssueHelpType.Consultation &&
                                            issue?.help_type !== EIssueHelpType.Video &&
                                            (!issue.address_parts?.lat || !issue.address_parts?.long)
                                                ? openChangeStatusToMasterSearchModal()
                                                : openChangeStatusModal({ newStatus: putStatus });
                                        } else {
                                            openChangeStatusModal({ newStatus: putStatus });
                                        }
                                    }}
                                    sx={{
                                        fontWeight: '600',
                                        background: colorHex,
                                        color: `${colorText} !important`,
                                    }}
                                    className="btn-status"
                                    key={name}
                                >
                                    {title}
                                </Box>
                            );
                        })}
                    </div>
                )}
                <EditIssueStatusWithComment
                    reason={chosenReason}
                    issueHelpType={issue.help_type}
                />
                <EditIssueStatusToCancel issueHelpType={issue.help_type} />
                <EditIssueStatusToMasterSearchWithoutCoords />
            </div>
        )
    );
}

export default memo(IssueStatus);
