import { Box, styled } from '@mui/material';

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '20px',
    marginTop: '20px',
});

export const CloseIconWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
});

export const FileData = styled('div')({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '10px',
    columnGap: '10px',
});

export const FilePreview = styled('object')({
    width: '70px',
    height: '70px',
    objectFit: 'cover',
});
