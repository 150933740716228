import { TableHead, TableRow } from '@mui/material';

import { HeadCell } from './styles';

export const Head = () => {
    return (
        <TableHead>
            <TableRow>
                <HeadCell>СТАТУС</HeadCell>
                <HeadCell>ИДЕНТИФИКАТОР</HeadCell>
                <HeadCell>НАИМЕНОВАНИЕ</HeadCell>
                <HeadCell>ТИП УСЛУГ</HeadCell>
                <HeadCell>НАИМЕНОВАНИЕ ПАРТНЕРА</HeadCell>
                <HeadCell>ИДЕНТИФИКАТОР ПАРТНЕРА</HeadCell>
                <HeadCell />
            </TableRow>
        </TableHead>
    );
};
