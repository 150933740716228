import Loader from 'components/Loader';
import { withWrapper } from 'hocs/with-wrapper';

import {
    Table as MuiTable,
    styled,
    TableCell,
    TableBody,
    TableRow,
    TableHead,
    TextField,
    InputBaseComponentProps,
    Tooltip,
    TooltipProps,
    tooltipClasses,
} from '@mui/material';
import { IWorkerDetail } from 'interfaces/workers';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';

type Props = {
    rows: (IWorkerDetail & { withdrawError: boolean })[];
    loading: boolean;
    onAmountChange: (id: string, value: number) => void;
};

export const Table: FC<Props> = ({ rows, loading, onAmountChange }) => {
    if (loading) return withWrapper(Wrapper, Loader);

    return (
        <MuiTable stickyHeader>
            <TableHead>
                <TableRow>
                    <HeadCell>ФИО</HeadCell>
                    <HeadCell align="center">БАЛАНС</HeadCell>
                    <HeadCell align="center">К ВЫВОДУ</HeadCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => {
                    return (
                        <TableRow key={row.id}>
                            <TableCell sx={{ maxWidth: '200px' }}>
                                {`${row.last_name || ''} ${row.first_name || ''} ${row.middle_name || ''}`}
                            </TableCell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={row.balance}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">
                                <ErrorTooltip
                                    open={row.withdrawError}
                                    title="Введена сумма больше баланса"
                                >
                                    <NumericFormat
                                        thousandSeparator=" "
                                        inputProps={CellInputProps}
                                        value={row.balance}
                                        customInput={TextField}
                                        suffix=" ₽"
                                        onValueChange={(e) => onAmountChange(row.id, +e.value)}
                                        error={row.withdrawError}
                                        decimalScale={2}
                                        isAllowed={(values) => {
                                            const { formattedValue: value, floatValue } = values;

                                            return value === '' || floatValue! > 0;
                                        }}
                                    />
                                </ErrorTooltip>
                            </Cell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};

const Wrapper = styled('div')({
    position: 'relative',
    padding: '100px 0',
});

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
});

const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};

const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});

const ErrorTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'red',
        backgroundColor: 'transparent',
        marginTop: '-4px !important',
    },
}));
