import { Popover } from '@mui/material';
import { FC } from 'react';

import { ButtonItem, MenuWrapper } from './styles';

type Props = {
    isOpenMenu: any;
    setOpenMenu: (value: boolean) => void;
    setOpenEditComment: (value: boolean) => void;
    setOpenEditStatus: (value: boolean) => void;
    setOpenConfirm: (value: boolean) => void;
    isHistoryComment: any;
};

export const Menu: FC<Props> = ({
    isOpenMenu,
    setOpenMenu,
    setOpenEditComment,
    setOpenEditStatus,
    setOpenConfirm,
    isHistoryComment,
}) => {
    return (
        <Popover
            onClose={() => setOpenMenu(false)}
            open={isOpenMenu}
            anchorEl={isOpenMenu}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{ '.MuiPaper-root': { borderRadius: '8px' } }}
        >
            <MenuWrapper>
                {isHistoryComment ? (
                    <ButtonItem
                        color="inherit"
                        onClick={() => {
                            if (isHistoryComment?.id) {
                                setOpenEditComment(true);
                            } else {
                                setOpenEditStatus(true);
                            }
                            setOpenMenu(false);
                        }}
                    >
                        {isHistoryComment?.id ? 'Редактировать комментарий' : 'Добавить комментарий'}
                    </ButtonItem>
                ) : (
                    <ButtonItem
                        color="inherit"
                        onClick={() => {
                            setOpenMenu(false);
                            setOpenEditComment(true);
                        }}
                    >
                        Редактировать
                    </ButtonItem>
                )}
                {isHistoryComment?.id || !isHistoryComment ? (
                    <ButtonItem
                        color="error"
                        onClick={() => {
                            setOpenMenu(false);
                            setOpenConfirm(true);
                        }}
                    >
                        {isHistoryComment?.id ? 'Удалить комментарий' : 'Удалить'}
                    </ButtonItem>
                ) : null}
            </MenuWrapper>
        </Popover>
    );
};
