import { Box, Paper, styled } from '@mui/material';

export const TitleWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'end',
    gap: '20px',
}); // double

export const ModalWrapper = styled(Paper)({
    marginTop: '20px',
    padding: '3px 18px 18px 18px',
    height: '100%',
    borderRadius: '8px',
    // overflow: 'hidden',
}); // double

export const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}); // double
