import { Button, Popover, styled } from '@mui/material';

export const StyledPopover = styled(Popover)({
    '.MuiPaper-root': {
        marginTop: '8px',
        padding: '10px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
});

export const Toolbar = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
    columnGap: '16px',
});

export const StyledButton = styled(Button)({
    padding: '14px',
});

export const ListItem = styled('div')({
    minHeight: '38px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    width: '230px',
});
