import { TPriceListServiceFilters } from 'pages/price-lists/store/types';
import { companyProfileSelector } from 'store/selectors/profileSelectors';

import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Typography, Link } from '@mui/material';
import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import IssueCreated from './IssueCreated';
import PartnerForm from './PartnerForm';

import { ECreateIssuePartnerSteps } from '../../enums/createIssuePartnerForm.enum';
import { EIssueHelpType } from '../../enums/issues.enum';
import { IPricesFilters } from '../../interfaces/prices';
import purpleLogoPNG from '../../static/images/purpleLogo.png';
import { getBrandsThunk } from '../../store/actions/brands';
import { getPriceListServiceThunk, getPricesThunk } from '../../store/actions/prices';
import { logout } from '../../store/actions/profile';
import { useAppDispatch } from '../../store/hooks';
import { formValuesSelector, stepSelector } from '../../store/selectors/create/createIssuePartnerSelectors';
import defaultErrorCallback from '../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../utils/helpers/getErrorString';
import { getQueryParamsAsString } from '../../utils/helpers/getQueryParamsAsString';

import './PartnerPageStyles.scss';

/* Необходимы т.к. в форме партнёра доступны только prices с типом установка,
	из with_additional строится список дополнительных услуг с.м. pricesIsAdditionalOptionsSelector  */
const defaultPricesParams: IPricesFilters = {
    help_type__in: EIssueHelpType.Installation,
    with_additional: true,
    with_paginate: false,
};

function PartnerPage() {
    const dispatch = useAppDispatch();

    const step = useSelector(stepSelector);
    const formValues = useSelector(formValuesSelector);
    const company = useSelector(companyProfileSelector);

    useEffect(() => {
        async function handle() {
            try {
                if (process.env.REACT_APP_NEW_PRICES_ENABLED !== 'true') {
                    await dispatch(getPricesThunk({ filters: defaultPricesParams }));
                }

                await dispatch(
                    getBrandsThunk(
                        getQueryParamsAsString([
                            {
                                /* 	TODO Warning!!! Mock данные чтоб, получить список брендов, бренд нужен при сохранении формы
									напоминать бэку выпилить при каждой возможности */
                                search: '-',
                            },
                            { order_by: 'name' },
                            { page: 1 },
                            { page_size: 10 },
                        ]),
                    ),
                );
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }

        handle();
    }, []);

    useEffect(() => {
        async function handle() {
            if (!formValues?.addressFiasId || !formValues?.categories?.length) return;
            const { address, addressFiasId, categories } = formValues;
            try {
                if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
                    if (addressFiasId || categories?.length) {
                        const params: TPriceListServiceFilters = {
                            help_type: EIssueHelpType.Installation,
                            company_id: company?.id,
                            is_active: true,
                            price_list__is_active: true,
                            with_general_price_list: true,
                            access_type__in: ['seller_form'],
                        };
                        if (addressFiasId) {
                            params.feature_type = 'region_fias_id';
                            params.region = addressFiasId;
                        }

                        if (categories?.length) {
                            params.category_id__in = categories.map(({ value }) => value);
                        }

                        await dispatch(getPriceListServiceThunk(params));
                    }
                } else {
                    await dispatch(
                        getPricesThunk({
                            filters: {
                                ...defaultPricesParams,
                                feature_type: addressFiasId ? 'region_fias_id' : 'string',
                                region: addressFiasId || address,
                            },
                        }),
                    );
                }
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, [formValues?.address, formValues?.addressFiasId, formValues?.categories]);

    return (
        <Box className="main-partner">
            <Box className="partner-content">
                <Box className="parner-header">
                    <div className="header-top">
                        <img
                            src={purpleLogoPNG}
                            alt="logo"
                        />
                        <Button
                            color="secondary"
                            startIcon={<LogoutIcon />}
                            onClick={() => dispatch(logout())}
                        >
                            Выйти из профиля
                        </Button>
                    </div>
                    <Typography
                        variant="h3"
                        fontWeight={600}
                        mt={5}
                    >
                        Установка техники
                    </Typography>
                    <div>
                        <Link
                            target="_blank"
                            href="https://saved-files.storage.yandexcloud.net/saved-files/9dce486f-43e0-4ae8-9d48-5bb887ac6f20"
                        >
                            Инструкция
                        </Link>
                    </div>
                </Box>
                {(() => {
                    switch (step) {
                        case ECreateIssuePartnerSteps.Success:
                            return <IssueCreated />;
                        default:
                            return <PartnerForm />;
                    }
                })()}
            </Box>
        </Box>
    );
}

export default memo(PartnerPage);
