import { servicesPriceListsApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import { fetchServicesComplex, setServicesComplex } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { TPriceListService } from 'pages/price-lists/store/types';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { EllipseGreen } from 'static/Icons/EllipseGreen';
import { useAppDispatch } from 'store/hooks';
import { calculatePercentage } from 'utils/helpers/calculatePercentage';

import { TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ConfirmDeleteComplexServiceModal } from './confirm-delete';

import { Cell, CellInputProps } from '../styles';

interface IComplexServiceItemProps {
    item: TPriceListService;
    priceListId: string | undefined;
}

export const ComplexServiceItem = (props: IComplexServiceItemProps) => {
    const { item, priceListId } = props;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { servicesComplex } = useSelector(priceListsSelector);

    const [commissionValue, setCommissionValue] = useState<number | null>(item?.commission?.commission_value ?? null);
    const [amountValue, setAmountValue] = useState<number | null>(item?.amount ?? null);
    const [commissionPartnerValue, setCommissionPartnerValue] = useState<number | null>(
        item?.commission_partner?.commission_value ?? null,
    );

    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);

    const handleDelete = (id: string) => {
        servicesPriceListsApi
            .deleteServicesComplex(id)
            .then(() => {
                enqueueSnackbar('Удаление успешно выполнено', { variant: 'success' });
                dispatch(fetchServicesComplex(priceListId));
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handleEdit = (id: string, data: any) => {
        servicesPriceListsApi
            .editServicesComplex(id, data)
            .then(({ data }) => {
                enqueueSnackbar('Изменение успешно выполнено', { variant: 'success' });
                dispatch(setServicesComplex(servicesComplex.map((item) => (item.id === data.id ? data : item))));
            })
            .catch((e) => {
                enqueueSnackbar(
                    e?.response?.data?.detail?.[0]?.msg === 'ensure this value is greater than or equal to 0'
                        ? 'Комиссия партнера не может быть отрицательной'
                        : e.response.data.error?.message ||
                              (typeof e.response?.data !== 'string' ? e.message : e.response?.data),
                    { variant: 'error' },
                );
            });
    };

    return (
        <TableRow key={item?.id}>
            <Cell
                sx={{
                    cursor: 'pointer',
                    borderRadius: '4px',
                    ':hover': { backgroundColor: '#f3f3f3' },
                }}
                onClick={() => {
                    navigate(
                        `/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}/${item?.id}/${ERoutes.EditComplexServices}`,
                    );
                }}
            >
                <EllipseGreen status={item?.is_active} />
                <span style={{ marginLeft: '5px' }}>{item?.name}</span>
            </Cell>
            <Cell>
                {item?.services.map((service: any) => {
                    return <div key={service.id}>{service.name}</div>;
                })}
            </Cell>
            <Cell align="center">
                <NumericFormat
                    inputProps={CellInputProps}
                    customInput={TextField}
                    suffix=" ₽"
                    value={amountValue}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                        setAmountValue(newValue);
                    }}
                    onBlur={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                        if (item?.amount === amountValue) {
                            return;
                        }

                        handleEdit(item?.id, { amount: newValue });
                    }}
                />
            </Cell>
            <Cell
                sx={{ padding: '16px 0' }}
                align="center"
            >
                {calculatePercentage(commissionValue as number, amountValue)}
            </Cell>
            <Cell align="center">
                <NumericFormat
                    inputProps={CellInputProps}
                    customInput={TextField}
                    suffix=" ₽"
                    value={commissionValue}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                        setCommissionValue(newValue);
                    }}
                    onBlur={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                        if (item?.commission?.commission_value === commissionValue) {
                            return;
                        }

                        handleEdit(item?.id, {
                            commission: { value: newValue },
                        });
                    }}
                />
            </Cell>

            <Cell align="center">
                <NumericFormat
                    inputProps={CellInputProps}
                    customInput={TextField}
                    suffix=" ₽"
                    value={commissionPartnerValue}
                    onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : 0;

                        setCommissionPartnerValue(newValue);
                    }}
                    onBlur={(e) => {
                        const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : 0;

                        if (item?.commission_partner?.commission_value === commissionValue) {
                            return;
                        }

                        handleEdit(item?.id, {
                            commission_partner: { value: newValue },
                        });
                    }}
                />
            </Cell>

            <Cell
                onClick={() => setIsOpenConfirmDelete(true)}
                sx={{ paddingLeft: 0, cursor: 'pointer' }}
            >
                <DeleteBasket />
            </Cell>
            <ConfirmDeleteComplexServiceModal
                isOpen={isOpenConfirmDelete}
                setIsOpen={setIsOpenConfirmDelete}
                handleConfirm={() => handleDelete(item?.id)}
                complexServiceName={item?.name}
            />
        </TableRow>
    );
};
