import { servicesPriceListsApi, servicesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import { fetchPriceList, fetchServiceComplex, setServiceComplex } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { TDescendants, TPriceListService, TPriceListServiceIncludeDescription } from 'pages/price-lists/store/types';
import { FootNote } from 'pages/services/components/footnote';
import { useAppDispatch } from 'store/hooks';
import { LoosePartial } from 'types';
import { selectMapper, Option } from 'utils/helpers/selectMapper';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, TextField, Typography, Checkbox, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    Asterisk,
    Container,
    Field,
    FieldsContainer,
    ModalWrapper,
    SubTitle,
    TitleWrapper,
    CellInputProps,
} from '../add-complex-services/styles';

const accessOptions: Option[] = [
    { label: 'Мастер', value: 'worker' },
    { label: 'Бэкофис', value: 'backoffice' },
    { label: 'Форма продавца', value: 'seller_form' },
    { label: 'Внешняя форма', value: 'external_form' },
    { label: 'API интеграции', value: 'api' },
];

export const EditComplexServices = () => {
    const dispatch = useAppDispatch();
    const { priceListId, serviceComplexId } = useParams<{ priceListId: string; serviceComplexId: string }>();

    const { serviceComplex, priceList } = useSelector(priceListsSelector);

    const [servicesOptions, setServicesOptions] = useState<Option[]>([]);
    const [activeServicesOptions, setActiveServicesOptions] = useState<Option[]>([]);
    const [activeAccessOptions, setActiveAccessOptions] = useState<any>([]);
    const [descendants, setDescendants] = useState<
        ((TDescendants & { service_id: string }) | TPriceListServiceIncludeDescription)[]
    >([]);
    const [isInit, setIsInit] = useState(false);

    const [amount, setAmount] = useState<number | null>(null);
    const [commission, setCommission] = useState<number | null>(null);
    const [commissionPartner, setCommissionPartner] = useState<number | null>(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [externalId, setExternalId] = useState('');

    const handleEditServiceComplex = (
        data: LoosePartial<TPriceListService>,
        withoutSnackbar?: boolean,
        onSuccessCb?: () => void,
    ) => {
        const [key, value] = Object.entries(data)[0] as [keyof TPriceListService, unknown];

        if (
            serviceComplex &&
            // @ts-ignore
            ((key === 'commission' && serviceComplex[key]?.commission_value !== value.value) ||
                (key !== 'commission' && serviceComplex[key] !== value))
        ) {
            servicesPriceListsApi
                .editServicesComplex(serviceComplex?.id || '', data)
                .then(({ data }) => {
                    if (!withoutSnackbar) {
                        enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                    }

                    dispatch(setServiceComplex(data));

                    if (onSuccessCb) {
                        onSuccessCb();
                    }
                })
                .catch((e) => {
                    if (!withoutSnackbar) {
                        enqueueSnackbar(
                            e?.response?.data?.detail?.[0]?.msg === 'ensure this value is greater than or equal to 0'
                                ? 'Комиссия партнера не может быть отрицательной'
                                : e.response.data.error?.message ||
                                      (typeof e.response?.data !== 'string' ? e.message : e.response?.data),
                            { variant: 'error' },
                        );
                    }
                });
        }
    };

    const handleEditService = (_: React.SyntheticEvent<Element, Event>, value: Option[]) => {
        const missingValue = activeServicesOptions.find(
            (firstItem) => !value.some((secondItem) => secondItem.value === firstItem.value),
        );
        const newValue = value.find(
            (firstItem) => !activeServicesOptions.some((secondItem) => secondItem.value === firstItem.value),
        );

        let services = new Array();

        if (newValue) {
            services = [{ service_id: newValue?.value, is_deleted: false }];
        } else if (missingValue) {
            services = [{ service_id: missingValue?.value, is_deleted: true }];
        }

        const body: Partial<{ services: Option[]; name: string; description: string }> = {
            services,
        };

        if (!serviceComplex?.services.length) {
            body.name = missingValue?.value || newValue?.label;
            body.description = missingValue?.value || newValue?.label;
        } else if (value.length < (serviceComplex?.services.length || 1) && value.length) {
            body.name = value[0].label;
            body.description = value[0].label;
        }

        const onSuccessEditServiceCb = () => {
            setActiveServicesOptions(value);
            const services_ids = value.map((item) => item.value);

            servicesApi.getDescendants({ services_ids }).then((res) => {
                setDescendants(res.data.map((item) => ({ ...item, service_id: item.id })));

                handleEditServiceComplex(
                    {
                        include_descriptions: res.data.map(({ id, is_include_in_price }) => ({
                            is_deleted: false,
                            service_id: id,
                            is_include_in_price,
                        })),
                    },
                    true,
                );
            });
        };

        handleEditServiceComplex(body, false, onSuccessEditServiceCb);
    };

    const handleGetServices = (value?: string) => {
        const params: Record<any, any> = {
            service_type_id__in: [serviceComplex?.service_type.id],
            company_id__in: [priceList?.company_id],
            is_root: true,
            is_active: true,
        };

        if (value) {
            // поиск
            params.search = value;
        } else {
            // получение списка при иницализации
            params.size = 50;
        }

        servicesApi
            .getServices(params)
            .then((res) => {
                setServicesOptions(res.data.items.map(selectMapper));
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handleSearchDebounced = useDebouncedCallback(handleGetServices, 700);

    const handleChangeAccessTypes = (_: React.SyntheticEvent<Element, Event>, value: Option[]) => {
        const newAccessTypes = value?.map((item) => item?.value);
        setActiveAccessOptions(value);
        handleEditServiceComplex({ access_types: newAccessTypes });
    };

    useEffect(() => {
        dispatch(fetchPriceList(priceListId as string));

        if (!serviceComplexId) return;

        dispatch(fetchServiceComplex(serviceComplexId));

        return () => {
            dispatch(setServiceComplex(null));
        };
    }, []);

    useEffect(() => {
        if (!serviceComplex) return;

        setName(serviceComplex.name);
        setDescription(serviceComplex.description);
        setExternalId(serviceComplex.external_id);

        if (!isInit) {
            setDescendants(serviceComplex.include_descriptions);
            setAmount(serviceComplex.amount);
            setCommission(
                typeof serviceComplex.commission?.commission_value === 'number'
                    ? serviceComplex.commission.commission_value
                    : null,
            );
            setCommissionPartner(
                typeof serviceComplex.commission_partner?.commission_value === 'number'
                    ? serviceComplex.commission_partner.commission_value
                    : null,
            );
            setActiveServicesOptions(
                serviceComplex.services.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            );
            if (serviceComplex.access_types) {
                setActiveAccessOptions(
                    serviceComplex.access_types.map((item) => ({
                        label: accessOptions.find((option) => (option?.value === item ? option?.label : ''))?.label,
                        value: item,
                    })),
                );
            }
            setIsInit(true);
        }

        if (servicesOptions?.length) return;
        handleGetServices();
    }, [serviceComplex]);

    return (
        <MainLayout>
            <Container>
                <TitleWrapper>
                    <Typography
                        fontWeight={700}
                        sx={{ fontSize: '28px' }}
                    >
                        Изменить комплекс услуг
                    </Typography>
                    <Button
                        sx={{ color: '#0040F1' }}
                        disabled={!priceListId}
                        component={Link}
                        to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`}
                    >
                        Назад
                    </Button>
                </TitleWrapper>
                <ModalWrapper>
                    <FieldsContainer>
                        <Field>
                            <SubTitle>Услуги</SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={servicesOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onInputChange={(_, value) => handleSearchDebounced(value)}
                                onChange={handleEditService}
                                value={activeServicesOptions ?? []}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Наименование<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                onBlur={(e) => {
                                    handleEditServiceComplex({ name: e.target.value.trim() });
                                }}
                                onChange={({ target }) => setName(target.value)}
                                value={name}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Описание</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                minRows={4}
                                onBlur={(e) => {
                                    handleEditServiceComplex({ description: e.target.value.trim() });
                                }}
                                onChange={({ target }) => setDescription(target.value)}
                                value={description}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Цена<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onBlur={(e) => {
                                    const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                                    if (newValue === amount) {
                                        return;
                                    }

                                    setAmount(newValue);
                                    handleEditServiceComplex({ amount: newValue });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                                value={amount}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Комиссия мастера<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onBlur={(e) => {
                                    const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : null;

                                    if (newValue === commission) {
                                        return;
                                    }

                                    setCommission(newValue);
                                    handleEditServiceComplex({ commission: { value: newValue } });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                                value={commission}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Комиссия партнера</SubTitle>
                            <NumericFormat
                                onBlur={(e) => {
                                    const newValue = e.target.value.slice(0, -2) ? +e.target.value.slice(0, -2) : 0;

                                    if (newValue === commissionPartner) {
                                        return;
                                    }

                                    setCommissionPartner(newValue);
                                    handleEditServiceComplex({ commission_partner: { value: newValue } });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                                value={commissionPartner}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                <div>Что входит в стоимость</div>
                                <div style={{ fontSize: '12px', color: '#A4A7B8' }}>
                                    Невыбранные элементы будут отображаться в блоке{' '}
                                    <div>«Что не входит в стоимость»</div>
                                </div>
                            </SubTitle>
                            <div>
                                {descendants.map((descendant) => {
                                    return (
                                        <div key={descendant.id}>
                                            <Checkbox
                                                checked={descendant.is_include_in_price}
                                                sx={{ padding: '5px 0' }}
                                                onChange={(_, value) => {
                                                    handleEditServiceComplex(
                                                        {
                                                            include_descriptions: [
                                                                {
                                                                    service_id: descendant.service_id,
                                                                    is_deleted: false,
                                                                    is_include_in_price: value,
                                                                },
                                                            ],
                                                        },
                                                        false,
                                                        () => {
                                                            setDescendants((prev) =>
                                                                prev.map((item) => {
                                                                    if (item.id === descendant.id) {
                                                                        return {
                                                                            ...item,
                                                                            is_include_in_price: value,
                                                                        };
                                                                    }

                                                                    return item;
                                                                }),
                                                            );
                                                        },
                                                    );
                                                }}
                                            />
                                            <span>{descendant.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Field>
                        <Divider sx={{ width: '600px' }} />
                        <Field>
                            <SubTitle>Активен</SubTitle>
                            <Checkbox
                                onClick={() =>
                                    handleEditServiceComplex({
                                        is_active: !serviceComplex?.is_active,
                                    })
                                }
                                sx={{ padding: '5px 0' }}
                                checked={!!serviceComplex?.is_active}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Плательщик по умолчанию<Asterisk>*</Asterisk>
                            </SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={['Клиент', 'Партнер']}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} />}
                                value={serviceComplex?.availability_type === 'client' ? 'Клиент' : 'Партнер'}
                                disableClearable
                                onChange={(_, value) => {
                                    handleEditServiceComplex({
                                        availability_type: value === 'Клиент' ? 'client' : 'partner',
                                    });
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Кому доступен</SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={accessOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onChange={handleChangeAccessTypes}
                                value={activeAccessOptions ?? []}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Внешний идентификатор</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                onBlur={(e) => handleEditServiceComplex({ external_id: e.target.value })}
                                value={externalId}
                                onChange={({ target }) => setExternalId(target.value)}
                            />
                        </Field>
                    </FieldsContainer>

                    <FootNote />
                </ModalWrapper>
            </Container>
        </MainLayout>
    );
};
