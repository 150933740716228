import { Box, styled } from '@mui/material';

export const CloseIconWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
});

export const ButtonsContainer = styled('div')({
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
});

export const TitleWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
});
