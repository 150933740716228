import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ERoutes } from 'enums/routes.enum';
import { setCompany } from 'pages/companies/store';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';

import { TableBody, TableRow, Popover, Tooltip } from '@mui/material';
import { ICompany } from 'interfaces/companies';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonItem, ButtonItemWrapper, Cell, ThreeDotsWrapper } from './styles';

import { Confirm } from '../modals/confirm';

type Props = {
    rows: ICompany[];
};

const serviceTypesOptions = [
    { label: 'Выбрать все', value: null },
    { label: 'Установка', value: 'installation' },
    { label: 'Коммерческий ремонт', value: 'repair' },
    { label: 'Гарантийный ремонт', value: 'warranty_repair' },
];

export const Body: FC<Props> = ({ rows }) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const { company } = useSelector(companiesPartnersSelector);
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    const [isOpen, setOpen] = useState<any>();
    const [isOpenConfirm, setOpenConfirm] = useState<any>();

    const handleCopy = (value: string) => {
        navigator.clipboard.writeText(value);
        enqueueSnackbar('Скопировано в буфер обмена.', { variant: 'success' });
    };

    const isSuperAdmin = profile?.is_superadmin;
    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <TableBody>
            {rows?.map((row) => {
                return (
                    <TableRow
                        key={row?.id}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`${row?.id}/${ERoutes.CompanyMain}`)}
                    >
                        <Cell>{row?.status === 'active' ? 'Активный' : 'Неактивный'}</Cell>
                        <Cell>
                            <Tooltip
                                title={'Копировать'}
                                placement="right"
                                followCursor
                            >
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopy(row?.id);
                                    }}
                                    style={{ cursor: 'pointer', padding: '10px' }}
                                >
                                    {row?.id ? `${row?.id?.slice(0, 15)}...` : '---'}
                                </span>
                            </Tooltip>
                        </Cell>
                        <Cell>{row?.name}</Cell>
                        <Cell>
                            {row?.service_types?.map((serviceType) => {
                                const service_type = serviceTypesOptions.find(
                                    (option) => option.value === serviceType,
                                )?.label;

                                return <div key={serviceType?.value}>{service_type}</div>;
                            })}
                        </Cell>
                        <Cell>{row?.partner_name}</Cell>
                        <Cell>
                            <Tooltip
                                title={'Копировать'}
                                placement="right"
                                followCursor
                            >
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopy(row?.partner_id);
                                    }}
                                    style={{ cursor: 'pointer', padding: '10px' }}
                                >
                                    {row?.partner_id ? `${row?.partner_id?.slice(0, 15)}...` : '---'}
                                </span>
                            </Tooltip>
                        </Cell>
                        <Cell>
                            {isSuperAdmin || isOwner || isCompanyOwner ? (
                                <ThreeDotsWrapper
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setOpen(e.currentTarget);
                                        dispatch(setCompany(row));
                                    }}
                                >
                                    <ThreeDots />
                                </ThreeDotsWrapper>
                            ) : null}
                        </Cell>
                    </TableRow>
                );
            })}

            <Popover
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorEl={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ '.MuiPaper-root': { borderRadius: '8px' } }}
            >
                <ButtonItemWrapper>
                    {rows.map((row) => {
                        if (row.id === company?.id && row?.status === 'hidden') {
                            return (
                                <ButtonItem
                                    key={row.id}
                                    onClick={() => {
                                        setOpenConfirm(true);
                                        setOpen(false);
                                    }}
                                    color="inherit"
                                >
                                    Активировать
                                </ButtonItem>
                            );
                        } else if (row.id === company?.id && row?.status === 'active') {
                            return (
                                <ButtonItem
                                    key={row.id}
                                    onClick={() => {
                                        setOpenConfirm(true);
                                        setOpen(false);
                                    }}
                                    color="inherit"
                                >
                                    Деактивировать
                                </ButtonItem>
                            );
                        }
                    })}
                </ButtonItemWrapper>
            </Popover>

            <Confirm
                isOpen={isOpenConfirm}
                setIsOpen={setOpenConfirm}
            />
        </TableBody>
    );
};
