import { CloseIcon } from 'static/Icons/CloseIcon';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { Box, Dialog, DialogTitle, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { CloseIconWrapper } from '../styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    setIsOpenCreate: (value: boolean) => void;
};

export const ConfirmationModal: FC<Props> = ({ isOpen, setIsOpen, setIsOpenCreate }) => {
    const { user } = useSelector(companiesPartnersSelector);

    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h5">
                        Пользователь {user?.email} добавлен. Пароль: {user?.password}
                    </Typography>
                    <CloseIconWrapper
                        onClick={() => {
                            setIsOpen(false);
                            setIsOpenCreate(false);
                        }}
                    >
                        <CloseIcon />
                    </CloseIconWrapper>
                </Box>
            </DialogTitle>
        </Dialog>
    );
};
