import { servicesPriceListsApi, servicesApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import MainLayout from 'layouts/MainLayout';
import {
    fetchPriceList,
    fetchServicesComplex,
    setServicesComplexFilters,
    initialState as priceListInitialState,
} from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { TDescendants } from 'pages/price-lists/store/types';
import { FootNote } from 'pages/services/components/footnote';
import { useAppDispatch } from 'store/hooks';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Autocomplete, Button, TextField, Typography, Checkbox, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-lodash-debounce';

import {
    Asterisk,
    ButtonsContainer,
    Container,
    Field,
    FieldsContainer,
    ModalWrapper,
    SubTitle,
    TitleWrapper,
    CellInputProps,
} from './styles';

export type TForm = {
    name: string;
    description: string;
    service_ids: string[];
    amount: number | undefined;
    commission: {
        value: number | undefined;
    };
    include_descriptions: any[];
    is_active_integration: boolean;
    availability_type: string;
    external_id: string | null;
    is_active: boolean | undefined;
    price_lists: {
        pk__in: string[];
        type: string;
    };
    access_types: string[] | null;
};

const initialState: TForm = {
    name: '',
    description: '',
    service_ids: [],
    amount: undefined,
    commission: {
        value: undefined,
    },
    include_descriptions: [],
    is_active_integration: true,
    availability_type: 'client',
    external_id: null,
    is_active: true,
    price_lists: {
        pk__in: [],
        type: 'chosen',
    },
    access_types: [],
};

const accessOptions: Omit<Option, 'description'>[] = [
    { label: 'Мастер', value: 'worker' },
    { label: 'Бэкофис', value: 'backoffice' },
    { label: 'Форма продавца', value: 'seller_form' },
    { label: 'Внешняя форма', value: 'external_form' },
    { label: 'API интеграции', value: 'api' },
];

type Option = { label: string; value: string; description: string };

export const AddComplexServices = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { priceListId } = useParams();

    const { priceList } = useSelector(priceListsSelector);

    const [form, setForm] = useState(initialState);
    const [servicesOptions, setServicesOptions] = useState<Option[]>([]);
    const [descendants, setDescendants] = useState<TDescendants[]>([]);
    const [isInit, setIsInit] = useState(false);

    const handleCreateServicesComplex = () => {
        servicesPriceListsApi
            .createServicesComplex({
                ...form,
                price_lists: { type: 'chosen', pk__in: [priceListId] },
            })
            .then(() => {
                dispatch(setServicesComplexFilters(priceListInitialState.servicesComplexFilters));
                dispatch(fetchServicesComplex(priceListId));

                enqueueSnackbar('Комплекс услуг добавлен', { variant: 'success' });
                navigate(`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`);
            })
            .catch((e) => {
                enqueueSnackbar(
                    e.response.data.error?.message ||
                        (typeof e.response?.data !== 'string' ? e.message : e.response?.data),
                    {
                        variant: 'error',
                    },
                );
            });
    };

    const handleSearch = (value: string) => {
        servicesApi
            .getServices({
                search: value,
                service_type_id__in: [priceList?.service_type?.id],
                company_id__in: [priceList?.company_id],
                is_root: true,
                is_active: true,
            })
            .then((res) => {
                setServicesOptions(
                    res.data.items.map((item) => ({
                        label: item.name,
                        value: item.id,
                        description: item.description,
                    })),
                );
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.response.data || e.message, {
                    variant: 'error',
                });
            });
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    useEffect(() => {
        if (priceListId && !priceList) {
            dispatch(fetchPriceList(priceListId as string));
        }

        if (priceList && !isInit) {
            setIsInit(true);
            servicesApi
                .getServices({
                    size: 50,
                    service_type_id__in: [priceList?.service_type?.id],
                    company_id__in: [priceList?.company_id],
                    is_root: true,
                    is_active: true,
                })
                .then((res) => {
                    setServicesOptions(
                        res.data.items.map((item) => ({
                            label: item.name,
                            value: item.id,
                            description: item.description,
                        })),
                    );
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.response.data || e.message, {
                        variant: 'error',
                    });
                });
        }
    }, [priceListId, priceList]);

    return (
        <MainLayout>
            <Container>
                <TitleWrapper>
                    <Typography
                        fontWeight={700}
                        sx={{ fontSize: '28px' }}
                    >
                        Добавить комплекс услуг
                    </Typography>
                    <Button
                        sx={{ color: '#0040F1' }}
                        component={Link}
                        to={`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`}
                    >
                        Назад
                    </Button>
                </TitleWrapper>
                <ModalWrapper>
                    <FieldsContainer>
                        <Field>
                            <SubTitle>Услуги</SubTitle>
                            <Autocomplete
                                loading
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={servicesOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onInputChange={(_, value) => handleSearchDebounced(value)}
                                onChange={(_, value) => {
                                    const services_ids = value.map((item) => item.value);

                                    setForm((prev) => {
                                        return {
                                            ...prev,
                                            service_ids: services_ids,
                                            name: value?.[0]?.label ?? '',
                                            description: value?.[0]?.description ?? '',
                                            // include_descriptions: [],
                                        };
                                    });

                                    if (services_ids.length) {
                                        servicesApi.getDescendants({ services_ids: services_ids ?? [] }).then((res) => {
                                            setDescendants(res.data);

                                            setForm((prev) => ({
                                                ...prev,
                                                include_descriptions: res.data.map(({ is_include_in_price, id }) => ({
                                                    is_include_in_price,
                                                    service_id: id,
                                                })),
                                            }));
                                        });
                                    } else {
                                        setDescendants([]);
                                    }
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Наименование<Asterisk>*</Asterisk>
                            </SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                onChange={(e) => {
                                    setForm((prev) => {
                                        return { ...prev, name: e.target.value };
                                    });
                                }}
                                value={form?.name}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Описание</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                multiline
                                minRows={4}
                                onChange={(e) => {
                                    setForm((prev) => {
                                        return { ...prev, description: e.target.value };
                                    });
                                }}
                                value={form?.description}
                            />
                        </Field>

                        <Field>
                            <SubTitle>
                                Цена<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onValueChange={(e) => {
                                    setForm((prev) => {
                                        return { ...prev, amount: e.floatValue };
                                    });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Комиссия мастера<Asterisk>*</Asterisk>
                            </SubTitle>
                            <NumericFormat
                                onValueChange={(e) => {
                                    setForm((prev) => {
                                        return { ...prev, commission: { value: e.floatValue } };
                                    });
                                }}
                                suffix=" ₽"
                                customInput={TextField}
                                inputProps={CellInputProps}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                <div>Что входит в стоимость</div>
                                <div style={{ fontSize: '12px', color: '#A4A7B8' }}>
                                    Невыбранные элементы будут отображаться в блоке{' '}
                                    <div>«Что не входит в стоимость»</div>
                                </div>
                            </SubTitle>
                            <div>
                                {descendants?.map((descendant) => {
                                    return (
                                        <div key={descendant.id}>
                                            <Checkbox
                                                sx={{ padding: '5px 0' }}
                                                checked={!!descendant.is_include_in_price}
                                                onClick={() => {
                                                    setDescendants((prev) =>
                                                        prev.map((item) =>
                                                            item.id === descendant.id
                                                                ? {
                                                                      ...item,
                                                                      is_include_in_price:
                                                                          !descendant.is_include_in_price,
                                                                  }
                                                                : item,
                                                        ),
                                                    );

                                                    setForm((prev) => {
                                                        return {
                                                            ...prev,
                                                            include_descriptions: prev.include_descriptions.map(
                                                                (item) =>
                                                                    item.service_id === descendant.id
                                                                        ? {
                                                                              ...item,
                                                                              is_include_in_price:
                                                                                  !descendant.is_include_in_price,
                                                                          }
                                                                        : item,
                                                            ),
                                                        };
                                                    });
                                                }}
                                            />
                                            <span>{descendant.name}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </Field>
                        <Divider sx={{ width: '600px' }} />
                        <Field>
                            <SubTitle>Активен</SubTitle>
                            <Checkbox
                                onClick={() =>
                                    setForm((prev) => ({
                                        ...prev,
                                        is_active: !prev.is_active,
                                    }))
                                }
                                sx={{ padding: '5px 0' }}
                                checked={form.is_active}
                            />
                        </Field>
                        <Field>
                            <SubTitle>
                                Плательщик по умолчанию<Asterisk>*</Asterisk>
                            </SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={['Клиент', 'Партнер']}
                                filterSelectedOptions
                                renderInput={(params) => <TextField {...params} />}
                                value={form.availability_type === 'client' ? 'Клиент' : 'Партнер'}
                                disableClearable
                                onChange={(_, value) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        availability_type: value === 'Клиент' ? 'client' : 'partner',
                                    }));
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Кому доступен</SubTitle>
                            <Autocomplete
                                sx={{ width: '392px' }}
                                multiple
                                size="small"
                                popupIcon={<ExpandMoreIcon />}
                                options={accessOptions ?? []}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Выбрать"
                                    />
                                )}
                                onChange={(_, value) => {
                                    const access_types = value?.map((item) => item?.value);

                                    setForm((prev) => ({ ...prev, access_types }));
                                }}
                            />
                        </Field>
                        <Field>
                            <SubTitle>Внешний идентификатор</SubTitle>
                            <TextField
                                sx={{ width: '392px' }}
                                size="small"
                                onChange={(e) => {
                                    setForm((prev) => {
                                        return { ...prev, external_id: e.target.value };
                                    });
                                }}
                            />
                        </Field>
                    </FieldsContainer>
                    <ButtonsContainer>
                        <Button
                            disabled={form.amount === undefined || form.commission.value === undefined || !form.name}
                            variant="contained"
                            onClick={handleCreateServicesComplex}
                        >
                            Добавить
                        </Button>
                        <Button
                            sx={{ marginRight: '190px' }}
                            variant="outlined"
                            onClick={() =>
                                navigate(`/${ERoutes.PriceLists}/${priceListId}/${ERoutes.PriceListComplexServices}`)
                            }
                        >
                            Отмена
                        </Button>
                        <FootNote />
                    </ButtonsContainer>
                </ModalWrapper>
            </Container>
        </MainLayout>
    );
};
