import { priceListsApi, servicesPriceListsApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';

import {
    TCompany,
    TComplexFilters,
    TFilters,
    TPriceList,
    TPriceListService,
    TServicesComplexSort,
    TState,
} from './types';

export const initialState: TState = {
    priceLists: [],
    priceList: null,

    servicesComplex: [],
    serviceComplex: null,

    company: null,

    descendants: [],

    services: [],

    filters: {
        search: '',
        pk__in: [],
        service_type_id__in: [],
        company_id__in: [],
        region_id__in: [],
        is_active: null,
    },

    servicesComplexFilters: {
        search: '',
        category_id__in: [],
        access_type__in: [],
        availability_type: null,
        has_not_amount: null,
        has_not_commission: null,
        has_not_amount_and_commission: null,
        has_not_amount_or_commission: null,
    },

    servicesComplexSort: '-created_at',

    pagination: {
        page: 1,
        size: 25,
        total: 0,
    },

    servicesComplexPagination: {
        page: 1,
        size: 50,
        total: 0,
    },

    serviceComplexLoading: false,
    loading: false,
};

const slice = createSlice({
    name: 'priceLists',
    initialState,
    reducers: (create) => ({
        setPriceLists: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.priceLists = payload;
        }),

        setPriceList: create.reducer((state, { payload }: PayloadAction<TPriceList | null>) => {
            state.priceList = payload;
        }),

        setCompany: create.reducer((state, { payload }: PayloadAction<TCompany | null>) => {
            state.company = payload;
        }),

        fetchPriceLists: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { pagination, filters } = (getState() as RootState).priceLists;

                const params = getFilledParams({
                    size: pagination.size,
                    page: pagination.page,
                    ...filters,
                    ordering: '-created_at',
                });

                const { data } = await priceListsApi.getPriceLists(params);

                dispatch(setPagination({ total: data.total }));

                dispatch(setPriceLists(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        fetchPriceList: create.asyncThunk<void, string>(
            async (id: string, { dispatch }) => {
                const { data } = await priceListsApi.getPriceList(id);

                dispatch(setPriceList(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        fetchServicesComplex: create.asyncThunk(
            async (id, { dispatch, getState }) => {
                const { servicesComplexFilters, servicesComplexPagination, servicesComplexSort } = (
                    getState() as RootState
                ).priceLists;

                const params = getFilledParams({
                    size: servicesComplexPagination.size,
                    page: servicesComplexPagination.page,
                    price_list_id: id,
                    ...servicesComplexFilters,
                    ordering: servicesComplexSort,
                });

                const { data } = await servicesPriceListsApi.getServicesComplex(params);

                dispatch(setServicesComplexPagination({ total: data.total }));

                dispatch(setServicesComplex(data.items));
            },
            {
                pending: (state) => {
                    state.serviceComplexLoading = true;
                },
                settled: (state) => {
                    state.serviceComplexLoading = false;
                },
            },
        ),
        setServicesComplex: create.reducer((state, { payload }: PayloadAction<TPriceListService[]>) => {
            state.servicesComplex = payload;
        }),
        setServicesComplexPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.servicesComplexPagination = { ...state.servicesComplexPagination, ...payload };
        }),
        setServicesComplexSort: create.reducer((state, { payload }: PayloadAction<TServicesComplexSort | null>) => {
            state.servicesComplexSort = payload;
        }),

        fetchServiceComplex: create.asyncThunk(
            async (id: string, { dispatch }) => {
                const { data } = await servicesPriceListsApi.getServiceComplex(id);

                dispatch(setServiceComplex(data));
            },
            {
                pending: (state) => {
                    state.serviceComplexLoading = true;
                },
                settled: (state) => {
                    state.serviceComplexLoading = false;
                },
            },
        ),
        setServiceComplex: create.reducer((state, { payload }: PayloadAction<TPriceListService | null>) => {
            state.serviceComplex = payload;
        }),

        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.filters = { ...state.filters, ...(payload as any) };
        }),

        setServicesComplexFilters: create.reducer((state, { payload }: PayloadAction<Partial<TComplexFilters>>) => {
            state.servicesComplexPagination = { ...state.servicesComplexPagination, page: 1 };
            state.servicesComplexFilters = { ...state.servicesComplexFilters, ...(payload as any) };
        }),
    }),
});

export const {
    fetchPriceLists,
    fetchPriceList,
    fetchServicesComplex,
    fetchServiceComplex,
    setPriceLists,
    setPriceList,
    setServicesComplex,
    setPagination,
    setFilters,
    setServiceComplex,
    setServicesComplexFilters,
    setServicesComplexPagination,
    setServicesComplexSort,
    setCompany,
} = slice.actions;

export default slice.reducer;
