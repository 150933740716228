import { companiesPartnersApi } from 'api';
import { fetchCompanies } from 'pages/companies/store';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { Button, DialogContent, DialogTitle, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsContainer, MuiDialog } from './styles';

import { CloseIconWrapper } from '../styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

export const Confirm: FC<Props> = ({ isOpen, setIsOpen }) => {
    const dispatch = useAppDispatch();

    const { company } = useSelector(companiesPartnersSelector);

    const handleChangeActive = () => {
        if (company?.status === 'active') {
            companiesPartnersApi
                .deactivateCompanyStatus(company?.id as string)
                .then(() => {
                    dispatch(fetchCompanies());
                    setIsOpen(false);

                    enqueueSnackbar('Компания деактивирована', {
                        variant: 'success',
                    });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        } else {
            companiesPartnersApi
                .activateCompanyStatus(company?.id as string)
                .then(() => {
                    dispatch(fetchCompanies());
                    setIsOpen(false);

                    enqueueSnackbar('Компания активирована', {
                        variant: 'success',
                    });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        }
    };

    return (
        <MuiDialog open={isOpen}>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Подтвердите действие</Typography>
                <CloseIconWrapper onClick={() => setIsOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                <Typography>
                    {`Вы действительно хотите ${
                        company?.status === 'active' ? 'деактивировать' : 'активировать'
                    } компанию ${company?.name} ?`}
                </Typography>
                <ButtonsContainer>
                    <Button
                        onClick={handleChangeActive}
                        variant="contained"
                    >
                        Да
                    </Button>
                    <Button
                        onClick={() => setIsOpen(false)}
                        variant="outlined"
                    >
                        Нет
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </MuiDialog>
    );
};
