// @ts-ignore // todo разобраться в чём проблема с типизацией в пакете она есть

import { issuesApi } from 'api';
import TextField from 'components/inputs/TextField';
import { EIssueStatus } from 'enums/issues.enum';
import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ECommissionsType } from 'enums/prices.enum';
import { pricesServicesCommissionsTypeMock } from 'mockData/pricesServices';
import {
    getAllPricesEditIssuePriceListServiceThunk,
    getAllPricesEditIssuePricesThunk,
} from 'store/actions/edit/editIssuePrices';
import { getIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import {
    allPricesEditIssuePricesSelector,
    errorsEditIssuePricesSelector,
    formValuesEditIssuePricesSelector,
    issueIdEditIssuePricesSelector,
    priceRecipientEditIssuePricesSelector,
    searchPricesOptionsEditIssuePricesSelector,
} from 'store/selectors/edit/editIssuePricesSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { profileSelector } from 'store/selectors/profileSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getDiscount } from 'utils/helpers/getDiscount';
import { getErrorString } from 'utils/helpers/getErrorString';
import { toDivideAmount } from 'utils/helpers/toDivideAmount';

import { Add } from '@mui/icons-material';
import Delete from '@mui/icons-material/Delete';
import EastIcon from '@mui/icons-material/East';
import {
    Autocomplete,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    TextField as TextFieldUi,
} from '@mui/material';
import { IOption } from 'interfaces';
import { IPriceFormValue, TIssuePrice } from 'interfaces/edit/editIssuePrices';
import { TPriceRecipient } from 'interfaces/issues';
import { IPrice } from 'interfaces/prices';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { ConfirmDelete } from './confirm-delete';

import './InputStack.scss';

function getCalculatedAmount(selectedPrice: number | null, priceDiscount: number | null): number {
    if (selectedPrice) {
        const price = Number.isNaN(Number(selectedPrice)) ? 0 : selectedPrice ?? 0;
        const discount = Number.isNaN(Number(priceDiscount)) ? 0 : priceDiscount ?? 0;
        return Number(((price * (100 - discount)) / 100).toFixed(2));
    } else {
        return Number(((0 * (100 - 0)) / 100).toFixed(2));
    }
}

export interface IProps {
    values?: IPriceFormValue[] | undefined;
    onChange: (values: IPriceFormValue[]) => void;
    priceType: TPriceRecipient;
}

function PriceInputStack({ values, onChange, priceType }: IProps) {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const issueId = useSelector(issueIdEditIssuePricesSelector);
    const profile = useSelector(profileSelector);
    const isPartnersPriceType = priceType === 'partner';
    const isDisabledFields =
        issue?.status === EIssueStatus.Solved ||
        issue?.status === EIssueStatus.SolvedNotPartner ||
        issue?.status === EIssueStatus.ActNRP;

    const priceRecipient = useSelector(priceRecipientEditIssuePricesSelector);
    const priceOptions = useSelector(searchPricesOptionsEditIssuePricesSelector);
    const prices = useSelector(allPricesEditIssuePricesSelector);
    const errors = useSelector(errorsEditIssuePricesSelector);

    const formValues = useSelector(formValuesEditIssuePricesSelector);

    const { address } = issue || {};

    const changePriceInpDebounced = useDebouncedCallback(changePriceInputHandler, 800);

    const [amountValues, setAmountValues] = useState<number[]>([]);
    const [stackValues, setStackValues] = useState(values || []);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [priceIdx, setPriceIdx] = useState<number | null>(null);
    const [issuePrice, setIssuePrice] = useState<any>(null);
    const [isChanged, setIsChanged] = useState<boolean>(false);
    const [isAddMode, setIsAddMode] = useState<boolean>(false);

    const clientPrices = issue?.price?.details.filter((priceDetail) => priceDetail.price_recipient === 'client') || [];
    const partnerPrices =
        issue?.price?.details.filter((priceDetail) => priceDetail.price_recipient === 'partner') || [];

    useEffect(() => {
        const newAmountValues = stackValues.map((value) => {
            if (value.discount_type === ECommissionsType.Percentage) {
                return getCalculatedAmount(value.value, value.discount || null);
            }

            return Number((value.value || 0) - (value?.discount || 0));
        });
        setAmountValues(newAmountValues);
    }, [stackValues]);

    async function changePriceInputHandler(val: React.FocusEvent<HTMLInputElement>) {
        try {
            if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
                if (issue) {
                    await dispatch(
                        getAllPricesEditIssuePriceListServiceThunk({
                            help_type: issue.help_type,
                            search: val.target.value || undefined,
                            company_id: issue.company_id,
                            is_active: true,
                            price_list__is_active: true,
                            region_id: issue.ratio_category_id,
                            access_type__in: ['backoffice'],
                        }),
                    );
                }
            } else {
                await dispatch(
                    getAllPricesEditIssuePricesThunk({
                        params: {
                            search: val.target.value || null,
                            region: address,
                            feature_type: 'string',
                            partner_id: issue?.partner?.id,
                            //category_id__in: categoryIds,
                        },
                    }),
                );
            }
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        }
    }

    function updateState(newValues: IPriceFormValue[], newAmountValues: number[]) {
        setStackValues(newValues);
        setAmountValues(newAmountValues);
        onChange && onChange(newValues);
    }

    const addIssuesPrices = (option: any, index: number) => {
        if (!issueId) return;

        const targetPrice = formValues?.prices?.find(
            (price, idx) => price?.price_id?.value === option?.value && idx === index,
        );
        const newPrice = {
            price_id: targetPrice?.price_id!.value!,
            discount:
                !isPartnersPriceType && isDisabledFields
                    ? 1
                    : getDiscount({ discount: targetPrice?.discount, discount_type: targetPrice?.discount_type }),
            amount: targetPrice?.value || 0,
            quantity: 1,
            price_recipient: targetPrice?.price_recipient,
            discount_type: !isPartnersPriceType && isDisabledFields ? 'percentage' : targetPrice?.discount_type,
        };

        issuesApi
            .addIssuePrices({ prices: [newPrice as TIssuePrice], issue_id: issueId as string })
            .then(() => {
                dispatch(getIssueThunk(issueId));
                enqueueSnackbar('Услуга добавлена', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            })
            .finally(() => {
                setIsAddMode(false);
            });
    };

    const editIssuesPrices = (data: any, index?: number) => {
        if (!issueId || !isChanged) return;

        const newPrice = {
            quantity: data.quantity,
            discount: getDiscount({ discount: data.discount, discount_type: data.discount_type }),
            discount_type: data.discount_type,
            amount: data.value,
            price_recipient: data?.price_recipient,
        };

        const clientPrice = clientPrices?.find(
            (item, idx) => item?.price?.id === data?.price_id?.value && idx === index,
        );
        const partnerPrice = partnerPrices?.find(
            (item, idx) => item?.price?.id === data?.price_id?.value && idx === index,
        );
        issuesApi
            .editIssuePrices(newPrice, priceType === 'client' ? clientPrice?.id! : partnerPrice?.id!)
            .then(() => {
                dispatch(getIssueThunk(issueId));

                enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                setIsChanged(false);
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const deleteIssuesPrices = (index: number) => {
        const clientPrice = clientPrices?.find(
            (item, idx) => item?.price?.id === issuePrice?.price_id?.value && idx === index,
        );
        const partnerPrice = partnerPrices?.find(
            (item, idx) => item?.price?.id === issuePrice?.price_id?.value && idx === index,
        );

        if (!issueId) return;
        if (!clientPrice?.id && !partnerPrice?.id) return;

        issuesApi
            .deleteIssuePrices(priceType === 'client' ? clientPrice?.id! : partnerPrice?.id!)
            .then(() => {
                dispatch(getIssueThunk(issueId));
                enqueueSnackbar('Услуга удалена', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.message, { variant: 'error' });
            });
    };

    function onChangePriceName(index: number, option: IOption<string> | null) {
        const newValues = [...stackValues];
        newValues[index].price_id = option;
        // @ts-ignore
        const price = prices?.find((pr) => pr.id === option?.value);
        if (process.env.REACT_APP_NEW_PRICES_ENABLED === 'true') {
            newValues[index].value = price?.amount || 0;
        } else {
            newValues[index].value = price?.ratios?.total_ratio_amount || 0;
        }

        updateState(newValues, amountValues);

        addIssuesPrices(option, index);
    }

    function onChangePriceDiscount(index: number, value: IPriceFormValue, event: React.ChangeEvent<HTMLInputElement>) {
        setIssuePrice(value);
        setIsChanged(true);
        const newValues = [...stackValues];
        const discount = +event.target.value;

        if (newValues[index].discount_type === ECommissionsType.Percentage) {
            if (Number.isNaN(discount)) return;
            if (Number(discount) > 100 || Number(discount) < 0) return;
            newValues[index].discount = +discount.toFixed(2);
        } else if (newValues[index].discount_type === ECommissionsType.Fixation) {
            if (Number.isNaN(discount)) return;

            if (value.value) {
                if (Number(discount) > value.value || Number(discount) < 0) return;
            } else {
                return;
            }

            newValues[index].discount = +discount.toFixed(2);
        }
        updateState(newValues, amountValues);
    }

    function onChangePriceValue(index: number, value: IPriceFormValue, event: React.ChangeEvent<HTMLInputElement>) {
        setIssuePrice(value);
        setIsChanged(true);
        const newValues = [...stackValues];
        const discountPrice = +event.target.value;

        newValues[index].value = discountPrice;
        newValues[index].service_amount = discountPrice;

        updateState(newValues, amountValues);
    }

    function onClickAddButton() {
        setIsAddMode(true);
        const newValues = [
            ...stackValues,
            {
                price_id: null,
                discount: null,
                value: null,
                quantity: 1,
                price_recipient: priceRecipient as TPriceRecipient,
                discount_type: ECommissionsType.Percentage,
            },
        ];
        const newAmountValues = [...amountValues, 0];
        updateState(newValues, newAmountValues);
    }

    function onClickDeleteButton(index: number) {
        setIsAddMode(false);
        const newValues = [...stackValues];
        newValues.splice(index, 1);
        const newAmountValues = [...amountValues];
        newAmountValues.splice(index, 1);
        updateState(newValues, newAmountValues);

        deleteIssuesPrices(index);
    }

    function onChangeCommissionsType(
        index: number,
        value: IPriceFormValue,
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        if (!issueId) return;
        setIsChanged(true);

        const newValues = [...stackValues];
        newValues[index].discount_type = event.target.value as ECommissionsType;
        newValues[index].discount = null;
        updateState(newValues, amountValues);

        editIssuesPrices(value, index);
        const newPrice = {
            quantity: value.quantity,
            discount: getDiscount({ discount: value.discount, discount_type: value.discount_type }),
            discount_type: value.discount_type,
            amount: value.value,
            price_recipient: value?.price_recipient,
        };

        const clientPrice = clientPrices?.find(
            (item, idx) => item?.price?.id === value?.price_id?.value && idx === index,
        );
        const partnerPrice = partnerPrices?.find(
            (item, idx) => item?.price?.id === value?.price_id?.value && idx === index,
        );

        issuesApi
            .editIssuePrices(newPrice, priceType === 'client' ? clientPrice?.id! : partnerPrice?.id!)
            .then(() => {
                dispatch(getIssueThunk(issueId));
                enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                setIsChanged(false);
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    }

    function onChangePriceCounter(index: number, value: IPriceFormValue, event: React.ChangeEvent<HTMLInputElement>) {
        setIssuePrice(value);
        setIsChanged(true);

        const newValues = [...stackValues];

        newValues[index].quantity = +event.target.value;

        updateState(newValues, amountValues);
    }

    const isSuperAdmin = profile?.is_superadmin;
    const isIssuesOwner = profile?.role?.permissions?.[EPermissionsKeys.Issues]?.includes(EPermissionsValues.Owner);
    const isIssuesSetPriceSolved = profile?.role?.permissions?.[EPermissionsKeys.Issues]?.includes(
        EPermissionsValues.SetPriceSolved,
    );

    const isHasAcces = isSuperAdmin || isIssuesOwner || isIssuesSetPriceSolved;

    return (
        <div className="price-inputs-container">
            {stackValues?.map((value, index) => {
                const name = index.toString();
                let errorMessage;
                let isDisabled = false;
                if (issue?.status === EIssueStatus.Solved) {
                    errorMessage =
                        Array.isArray(errors) &&
                        errors?.find((price: any) => price.id === index && value.value)?.message;
                    isDisabled = !!value.id;
                    console.log('errors', errors);
                }
                return (
                    <div
                        className="price-input"
                        key={name}
                    >
                        <div className="price-input-title">Услуга {index + 1}</div>
                        <Autocomplete
                            placeholder="Название услуги"
                            loadingText="Загрузка..."
                            //@ts-ignore
                            value={value?.price_id || ''}
                            options={priceOptions || []}
                            disabled={isDisabled || !!value?.price_id}
                            onFocus={changePriceInpDebounced}
                            onChange={(event, option) => onChangePriceName(index, option)}
                            isOptionEqualToValue={() => true}
                            renderInput={(params) => <TextFieldUi {...params} />}
                            renderOption={(props, option) => {
                                const isPartner = (prices as IPrice[])?.find(
                                    (price) => price?.availability_type === 'partner' && price?.id === option?.value,
                                );

                                return (
                                    <li
                                        {...props}
                                        key={option.value}
                                        className="item"
                                    >
                                        {option.label} {option.amount && <>({toDivideAmount(option.amount)} ₽)</>}
                                        {option.serviceName && (
                                            <div className="descr">
                                                <span>{option.serviceName}</span>
                                                {option.categoryName && <EastIcon fontSize="small" />}
                                                <span>{option.categoryName}</span>
                                                {option.isAdditional ? <span>(доп. услуга)</span> : null}
                                                {isPartner ? <span>(Партнер)</span> : null}
                                            </div>
                                        )}
                                    </li>
                                );
                            }}
                        />
                        {value?.price_id ? (
                            <>
                                <FormControl
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        flexDirection: 'row',
                                        margin: '10px 0',
                                    }}
                                >
                                    <FormLabel id="demo-radio-buttons-group-label">Выбрать тип скидки</FormLabel>
                                    <RadioGroup
                                        row
                                        name="category_radio"
                                        defaultValue={value.discount_type}
                                        onChange={(event) => onChangeCommissionsType(index, value, event)}
                                    >
                                        {/* eslint-disable-next-line no-shadow */}
                                        {Object.entries(pricesServicesCommissionsTypeMock).map(([key, value]) => (
                                            <FormControlLabel
                                                disabled={
                                                    isPartnersPriceType
                                                        ? isDisabledFields && !isSuperAdmin
                                                            ? true
                                                            : false
                                                        : isDisabledFields
                                                }
                                                key={key}
                                                value={key}
                                                control={<Radio />}
                                                label={value}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>

                                <div className="discount-amount">
                                    <div>
                                        <TextField
                                            name={`price${name}`}
                                            labelText={'Цена'}
                                            params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                                            sxStyle={{ width: '180px' }}
                                            disabled={
                                                isPartnersPriceType
                                                    ? isDisabledFields && !isSuperAdmin
                                                        ? true
                                                        : false
                                                    : isDisabledFields
                                            }
                                            value={value.service_amount || value.value}
                                            onChange={(event) => onChangePriceValue(index, value, event)}
                                            onBlur={() => editIssuesPrices(value, index)}
                                        />
                                        {errorMessage && (
                                            <div style={{ fontSize: '10px', color: 'red' }}>{errorMessage}</div>
                                        )}
                                    </div>
                                    <div>
                                        <TextField
                                            name={`discount${name}`}
                                            labelText={`Скидка (${
                                                pricesServicesCommissionsTypeMock[value.discount_type!]
                                            })`}
                                            params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                                            type="number"
                                            sxStyle={{ width: '180px' }}
                                            disabled={
                                                isPartnersPriceType
                                                    ? isDisabledFields && !isSuperAdmin
                                                        ? true
                                                        : false
                                                    : isDisabledFields
                                            }
                                            value={
                                                !value?.id && !isPartnersPriceType && isDisabledFields
                                                    ? 100
                                                    : value.discount || ''
                                            }
                                            onChange={(event) => onChangePriceDiscount(index, value, event)}
                                            onBlur={() => editIssuesPrices(value, index)}
                                        />
                                        {errorMessage && (
                                            <div style={{ fontSize: '10px', color: 'red' }}>{errorMessage}</div>
                                        )}
                                    </div>
                                    <div className="amount">
                                        <div className="amount-title">Цена со скидкой</div>
                                        <div className="amount-value">
                                            {!value?.id && !isPartnersPriceType && isDisabledFields
                                                ? 0
                                                : amountValues[index] || 0}
                                        </div>
                                    </div>
                                </div>

                                <div className="discount-amount">
                                    <div>
                                        <TextField
                                            name={`counter${name}`}
                                            labelText={'Количество'}
                                            params={{ margin: 'dense', inputProps: { maxLength: 500 } }}
                                            type="number"
                                            sxStyle={{ width: '180px' }}
                                            disabled={
                                                isPartnersPriceType
                                                    ? isDisabledFields && !isSuperAdmin
                                                        ? true
                                                        : false
                                                    : isDisabledFields
                                            }
                                            value={value.quantity || ''}
                                            onChange={(event) => {
                                                if (+event.target.value < 1) return;
                                                onChangePriceCounter(index, value, event);
                                            }}
                                            onBlur={() => editIssuesPrices(value, index)}
                                        />
                                        {errorMessage && (
                                            <div style={{ fontSize: '10px', color: 'red' }}>{errorMessage}</div>
                                        )}
                                    </div>
                                    <div className="amount">
                                        <div className="amount-title">Сумма</div>
                                        <div className="amount-value">
                                            {!value?.id && !isPartnersPriceType && isDisabledFields
                                                ? '0.00'
                                                : (value.quantity * amountValues[index]).toFixed(2)}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="delete-button">
                            <IconButton
                                disableRipple
                                onClick={() => {
                                    setIssuePrice(value);
                                    setPriceIdx(index);
                                    setIsOpen(true);
                                }}
                                disabled={
                                    isPartnersPriceType
                                        ? isDisabledFields && !isSuperAdmin
                                            ? true
                                            : false
                                        : isDisabledFields
                                }
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    </div>
                );
            })}
            <ConfirmDelete
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                onClickDeleteButton={onClickDeleteButton}
                idx={priceIdx}
            />

            {!isPartnersPriceType && !isAddMode ? (
                <div className="add-button">
                    <Button
                        startIcon={<Add />}
                        onClick={onClickAddButton}
                        disabled={isDisabledFields && isHasAcces ? false : !isDisabledFields ? false : true}
                    >
                        Добавить услугу
                    </Button>
                </div>
            ) : null}

            {isPartnersPriceType && !isAddMode ? (
                <div className="add-button">
                    <Button
                        disabled={
                            isPartnersPriceType ? (isDisabledFields && !isSuperAdmin ? true : false) : isDisabledFields
                        }
                        startIcon={<Add />}
                        onClick={onClickAddButton}
                    >
                        Добавить услугу
                    </Button>
                </div>
            ) : null}
        </div>
    );
}

export default PriceInputStack;
