import { issuesApi } from 'api';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { getIssueThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';

import AddIcon from '@mui/icons-material/Add';
import { Button, styled, Tooltip } from '@mui/material';
import Paper from '@mui/material/Paper';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { AddComment } from './components/modals/add-comment';
import { IssueHistory } from './issue-history';
import { fetchHistoryComments, setHistoryModalIsOpen } from './store';
import { commentsHistorySelector } from './store/selectors';
import {
    AddButton,
    CloseIconWrapper,
    MuiArrowIcon,
    MuiBox,
    MuiDrawer,
    MuiIconButton,
    Title,
    TitleWrapper,
} from './styles';

import './IssueCommentsWrapper.scss';

function IssueCommentsWrapper() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const { historyModalIsOpen } = useSelector(commentsHistorySelector);

    const [isOpenDialog, setOpenDialog] = useState<boolean>(false);

    const addComment = (value: string) => {
        if (issue) {
            issuesApi
                .addComment(issue.id, { comment: value })
                .then(() => {
                    setOpenDialog(false);
                    dispatch(getIssueThunk(issue.id));
                    dispatch(fetchHistoryComments(issue.id));

                    enqueueSnackbar('Комментарий добавлен', { variant: 'success' });
                })
                .catch((e) => {
                    enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
                });
        }
    };

    return (
        <Paper className="issue-comments-wrapper">
            <TitleWrapper>
                <MuiBox>
                    <MuiIconButton onClick={() => dispatch(setHistoryModalIsOpen(!historyModalIsOpen))}>
                        <MuiArrowIcon isOpen={historyModalIsOpen} />
                    </MuiIconButton>
                    <Title>История изменений</Title>
                </MuiBox>
                <LightTooltip
                    title={
                        <div>
                            Нажмите, чтобы написать
                            <br /> комментарий
                        </div>
                    }
                    placement="left"
                    arrow
                >
                    <Button
                        sx={{ color: 'black', minWidth: '30px' }}
                        onClick={() => setOpenDialog(true)}
                    >
                        <AddIcon fontSize="medium" />
                    </Button>
                </LightTooltip>
            </TitleWrapper>

            <MuiDrawer
                open={Boolean(historyModalIsOpen)}
                anchor="right"
                onClose={() => dispatch(setHistoryModalIsOpen(false))}
            >
                <TitleWrapper>
                    <MuiBox>
                        <MuiIconButton onClick={() => dispatch(setHistoryModalIsOpen(!historyModalIsOpen))}>
                            <MuiArrowIcon isOpen={historyModalIsOpen} />
                        </MuiIconButton>
                        <Title>История изменений</Title>
                        <LightTooltip
                            title={
                                <div>
                                    Нажмите, чтобы написать
                                    <br /> комментарий
                                </div>
                            }
                            placement="right"
                            arrow
                        >
                            <AddButton onClick={() => setOpenDialog(true)}>
                                <AddIcon fontSize="medium" />
                            </AddButton>
                        </LightTooltip>
                    </MuiBox>
                    <CloseIconWrapper onClick={() => dispatch(setHistoryModalIsOpen(false))}>
                        <CloseIcon />
                    </CloseIconWrapper>
                </TitleWrapper>
                <IssueHistory />
            </MuiDrawer>

            {!historyModalIsOpen ? <IssueHistory /> : null}

            <AddComment
                addComment={addComment}
                isOpenDialog={isOpenDialog}
                setOpenDialog={setOpenDialog}
            />
        </Paper>
    );
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#E9ECEF',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

export default IssueCommentsWrapper;
