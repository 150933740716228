import { priceListsApi } from 'api';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { CloseIcon } from 'static/Icons/CloseIcon';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsContainer, CloseIconWrapper, FileData } from './styles';

type Props = {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
};

export const ImportModal: FC<Props> = ({ isOpen, setIsOpen }) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const { priceList } = useSelector(priceListsSelector);

    const [file, setFile] = useState<File[] | null>(null);

    const handleUploadFile = async (event: any) => {
        const inputFile = event.target.files?.[0];

        if (inputFile) {
            const filesArr: File[] = [];
            filesArr.push(inputFile);
            setFile(filesArr);
        } else {
            setFile(null);
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const importPriceList = async () => {
        if (!priceList) return;

        if (file) {
            const formData = new FormData();
            formData.append('file', file?.[0]);

            try {
                await priceListsApi.importPriceList(formData, priceList.id);

                setIsOpen(false);
                enqueueSnackbar('Файл успешно импортирован', { variant: 'success' });
            } catch (error: any) {
                console.log('error', error);

                enqueueSnackbar(
                    error.response.data?.detail?.[0]?.msg === 'none is not an allowed value' ||
                        error.response.data?.detail?.[0]?.type === 'type_error.none.not_allowed'
                        ? 'Некорректный формат прайс-листа'
                        : error.response.data.error?.message || error.message,
                    { variant: 'error' },
                );
            } finally {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
            }
        }
    };

    return (
        <Dialog
            open={isOpen}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Импортировать файл</Typography>
                <CloseIconWrapper
                    onClick={() => {
                        setIsOpen(false);
                        if (fileInputRef.current) {
                            fileInputRef.current.value = '';
                        }
                        setFile(null);
                    }}
                >
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                {file?.length ? (
                    <div style={{ display: 'flex' }}>
                        <FileData>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div>
                                    <InsertDriveFileIcon sx={{ width: '50px', height: '55px', color: '#0040F1' }} />
                                </div>
                                <div title={file?.[0]?.name}>{file?.[0]?.name}</div>
                            </div>
                            <IconButton
                                onClick={() => {
                                    if (fileInputRef.current) {
                                        fileInputRef.current.value = '';
                                    }
                                    setFile(null);
                                }}
                                sx={{ marginLeft: 'auto' }}
                            >
                                <DeleteOutlineIcon />
                            </IconButton>
                        </FileData>
                    </div>
                ) : null}
                <input
                    type="file"
                    ref={fileInputRef}
                    accept=".xlsx"
                    name="file"
                    style={{ display: 'none' }}
                    onChange={handleUploadFile}
                />

                <ButtonsContainer>
                    <Button
                        variant="outlined"
                        onClick={() => fileInputRef.current!.click()}
                    >
                        Загрузить файл
                    </Button>
                    <Button
                        variant="contained"
                        onClick={importPriceList}
                        disabled={!file?.length}
                    >
                        Сохранить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
