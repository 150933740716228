import { CloseIcon } from 'static/Icons/CloseIcon';
import { commentsHistorySelector } from 'store/selectors/commentsHistorySelector';

import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ButtonsContainer, CloseIconWrapper, SubTitle } from '../styles';

type Props = {
    isOpenEditComment: boolean;
    setOpenEditComment: (value: boolean) => void;
    editComment: (value: string) => void;
};

export const EditComment: FC<Props> = ({ isOpenEditComment, setOpenEditComment, editComment }) => {
    const { choosedComment } = useSelector(commentsHistorySelector);

    const [value, setValue] = useState<string>(choosedComment?.comment || '');

    useEffect(() => {
        if (!choosedComment) return;
        setValue(choosedComment?.comment);
    }, [choosedComment]);

    return (
        <Dialog
            open={isOpenEditComment}
            sx={{
                '.MuiDialog-paper': {
                    width: '480px',
                    borderRadius: '8px',
                    position: 'relative',
                },
            }}
        >
            <DialogTitle>
                <Typography variant="h5">Редактировать комментарий</Typography>
                <CloseIconWrapper
                    onClick={() => {
                        setOpenEditComment(false);
                        setValue('');
                    }}
                >
                    <CloseIcon />
                </CloseIconWrapper>
            </DialogTitle>
            <DialogContent>
                <SubTitle>Данный комментарий увидят только пользователи админки</SubTitle>
                <TextField
                    sx={{ width: '100%', marginTop: '15px' }}
                    multiline
                    minRows={3}
                    placeholder="Комментарий"
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />
                <ButtonsContainer>
                    <Button
                        onClick={() => {
                            setOpenEditComment(false);
                            setValue('');
                        }}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                    <Button
                        onClick={() => {
                            editComment(value);
                            setOpenEditComment(false);
                            setValue('');
                        }}
                        variant="contained"
                        disabled={!value}
                    >
                        Сохранить
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
