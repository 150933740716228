import { Button, InputBaseComponentProps, Popover, styled, TableCell, TablePagination } from '@mui/material';

export const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    // textAlign: 'center',
}); // double

export const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    // textAlign: 'center',
}); // double

export const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
}; // double

export const ControlsWrapper = styled('div')({
    display: 'flex',
    gap: '12px',
});

export const StyledPopover = styled(Popover)({
    '.MuiPaper-root': {
        marginTop: '8px',
        padding: '10px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column',
    },
});

export const StyledButton = styled(Button)({
    padding: '14px',
});

export const ListItem = styled('div')({
    minHeight: '38px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '14px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
    width: '460px',
    cursor: 'pointer',
});

export const Wrapper = styled('div')({
    marginTop: '15px',
    position: 'relative',
    overflow: 'auto',
    flexGrow: 1,
    flexBasis: 0,
});

export const Pagination = styled(TablePagination)({
    display: 'block',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
});
