import { clientTagsApi } from 'api';
import { selectMapper } from 'utils/helpers/selectMapper';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import { Autocomplete, Box, Chip, IconButton, Stack, TextField } from '@mui/material';
import { IClientTag } from 'interfaces/tags';
import { useEffect, useState } from 'react';

interface IEditIssueClientTagsProps {
    id: string;
}

export const EditIssueClientTags = ({ id }: IEditIssueClientTagsProps) => {
    const [tags, setTags] = useState(new Array<IClientTag>());
    const [clientTags, setClientTags] = useState(new Array<IClientTag>());
    const [clientEditingTags, setClientEditingTags] = useState(new Array<IClientTag>());

    const [isEdit, setIsEdit] = useState(false);

    const handleGetTags = async () => {
        const { data } = await clientTagsApi.getTags({});
        setTags(data.items);
    };

    const handleEditTags = async () => {
        const filteredDeletedTags = clientTags
            .filter((tag) => !clientEditingTags.some((editingTag) => editingTag.id === tag.id))
            .map((item) => item.id);
        const filteredNewTags = clientEditingTags
            .filter((item) => !clientTags.some((tag) => tag.id === item.id))
            .map((item) => item.id);

        if (filteredDeletedTags.length) {
            await clientTagsApi.deleteClientTag(id, filteredDeletedTags);
        }

        if (filteredNewTags.length) {
            await clientTagsApi.editClientTags(id, filteredNewTags);
        }

        setClientTags(clientEditingTags);
        setIsEdit(false);
    };

    useEffect(() => {
        const getClientTags = async () => {
            const { data } = await clientTagsApi.getClientTags(id);
            setClientTags(data);
            setClientEditingTags(data);
        };

        handleGetTags();
        getClientTags();
        setIsEdit(false);
    }, [id]);

    return isEdit ? (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                flexWrap: 'wrap',
                width: '100%',
            }}
        >
            <Stack
                direction="row"
                spacing={1}
                flexWrap="wrap"
            >
                {clientEditingTags.map((tag) => (
                    <Chip
                        key={tag.id}
                        label={tag.name}
                        color={tag.kind === 'negative' ? 'error' : tag.kind === 'positive' ? 'success' : 'warning'}
                        onDelete={() => setClientEditingTags((prev) => prev.filter((item) => item.id !== tag.id))}
                        sx={{ marginBottom: '8px' }}
                    />
                ))}
            </Stack>
            <Stack
                direction="row"
                alignItems="center"
            >
                <Autocomplete
                    size="small"
                    disablePortal
                    options={tags.map(selectMapper)}
                    sx={{ width: 200 }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Теги"
                        />
                    )}
                    onChange={(_, value, reason) => {
                        if (reason === 'selectOption') {
                            const item = tags.find((item) => item.id === value?.value) as IClientTag;
                            const isIncludes = clientEditingTags.find((tag) => tag.id === item.id);
                            if (!isIncludes) {
                                setClientEditingTags((prev) => [...prev, item]);
                            }
                        }
                    }}
                />
                <div>
                    <IconButton
                        size="small"
                        title="Сохранить"
                        onClick={() => handleEditTags()}
                        sx={{ marginRight: '10px' }}
                    >
                        <CheckIcon sx={{ width: '15px', height: '15px' }} />
                    </IconButton>
                    <IconButton
                        size="small"
                        title="Отмена"
                        onClick={() => {
                            setClientEditingTags(clientTags);
                            setIsEdit(false);
                        }}
                    >
                        <ClearIcon sx={{ width: '15px', height: '15px' }} />
                    </IconButton>
                </div>
            </Stack>
        </Box>
    ) : (
        <Box sx={{ width: '100%' }}>
            <Stack
                direction="row"
                spacing={1}
                flexWrap="wrap"
                alignItems="center"
            >
                {clientTags.map((tag) => (
                    <Chip
                        key={tag.id}
                        label={tag.name}
                        color={tag.kind === 'negative' ? 'error' : tag.kind === 'positive' ? 'success' : 'warning'}
                        sx={{ marginBottom: '8px !important' }}
                    />
                ))}
                <IconButton
                    size="small"
                    onClick={() => setIsEdit(true)}
                >
                    <EditSharpIcon sx={{ width: '15px', height: '15px' }} />
                </IconButton>
            </Stack>
        </Box>
    );
};
