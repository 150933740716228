import Loader from 'components/Loader';
import MainLayout from 'layouts/MainLayout';
import { useAppDispatch } from 'store/hooks';
import { companiesPartnersSelector } from 'store/selectors/companiesSelector';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Controls } from './components/controls';
import { Table } from './components/table';
import { fetchCompanies } from './store';
import { Container } from './styles';

export const CompaniesPage = () => {
    const dispatch = useAppDispatch();

    const { loading, companies } = useSelector(companiesPartnersSelector);

    useEffect(() => {
        dispatch(fetchCompanies());
    }, []);

    return (
        <MainLayout>
            <Container>
                <Controls />
                {loading ? <Loader /> : <Table rows={companies} />}
            </Container>
        </MainLayout>
    );
};
