import { EPermissionsKeys, EPermissionsValues } from 'enums/permissions.enum';
import { ERoutes } from 'enums/routes.enum';
import { getTotalUnseenCountThunk } from 'store/actions/chats';
import { getWorkersCountThunk } from 'store/actions/workers';
import { useAppDispatch } from 'store/hooks';
import { chatsTotalUnseenCountSelector } from 'store/selectors/chatsSelectors';
import { companyProfileSelector, profileSelector } from 'store/selectors/profileSelectors';
import { isOpenSideBarSelector } from 'store/selectors/sideBarSelectors';
import { countWorkersSelector } from 'store/selectors/workersSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import StorageRoundedIcon from '@mui/icons-material/StorageRounded';
import TextsmsIcon from '@mui/icons-material/Textsms';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import './SideBarStyles.scss';

function SideBar() {
    const dispatch = useAppDispatch();

    const isOpen = useSelector(isOpenSideBarSelector);
    const countWorkers = useSelector(countWorkersSelector);
    const countChats = useSelector(chatsTotalUnseenCountSelector);
    const profile = useSelector(profileSelector);
    const companyProfile = useSelector(companyProfileSelector);

    useEffect(() => {
        async function handle() {
            try {
                await dispatch(getWorkersCountThunk());
                await dispatch(getTotalUnseenCountThunk());
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            }
        }
        handle();
    }, []);

    const getNavLinkClass = (prop: { isActive: boolean }) => {
        return `nav-link ${prop.isActive ? 'active' : ''}`;
    };

    const isSuperAdmin = profile?.is_superadmin;

    const isPriceListOwner = profile?.role?.permissions?.[EPermissionsKeys.PriceLists]?.includes(
        EPermissionsValues.Owner,
    );
    const isPriceListCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.PriceLists]?.includes(
        EPermissionsValues.Owner,
    );
    const isOwner = profile?.role?.permissions?.[EPermissionsKeys.Accounting]?.includes(EPermissionsValues.Owner);
    const isCompanyOwner = companyProfile?.permissions?.[EPermissionsKeys.Accounting]?.includes(
        EPermissionsValues.Owner,
    );

    return (
        <Paper className={cn('side-bar', { 'is-open': isOpen })}>
            <MenuList>
                <NavLink
                    to={`/${ERoutes.Issues}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <HomeIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Главная
                            </Typography>
                        )}
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={`/${ERoutes.Workers}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <BusinessCenterIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Мастера
                            </Typography>
                        )}
                        {!!countWorkers && <span className="side-bar-count">{countWorkers}</span>}
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={`/${ERoutes.Prices}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <TextSnippetIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Прайсы
                            </Typography>
                        )}
                    </MenuItem>
                </NavLink>
                {isPriceListOwner || isPriceListCompanyOwner || isSuperAdmin ? (
                    <>
                        <NavLink
                            to={`/${ERoutes.Services}`}
                            className={getNavLinkClass}
                        >
                            <MenuItem>
                                <ListItemIcon>
                                    <LocalActivityIcon fontSize="large" />
                                </ListItemIcon>
                                {isOpen && (
                                    <Typography
                                        variant="inherit"
                                        ml={1}
                                    >
                                        Услуги
                                    </Typography>
                                )}
                            </MenuItem>
                        </NavLink>
                        <NavLink
                            to={`/${ERoutes.PriceLists}`}
                            className={getNavLinkClass}
                        >
                            <MenuItem>
                                <ListItemIcon>
                                    <StorageRoundedIcon fontSize="large" />
                                </ListItemIcon>
                                {isOpen && (
                                    <Typography
                                        variant="inherit"
                                        ml={1}
                                    >
                                        Прайс-листы
                                    </Typography>
                                )}
                            </MenuItem>
                        </NavLink>
                    </>
                ) : null}
                {/* <NavLink to={`/${ERoutes.Chats}`} className={getNavLinkClass}>
					<MenuItem>
						<ListItemIcon>
							<TextsmsIcon fontSize="large" />
						</ListItemIcon>
						{isOpen && (
							<Typography variant="inherit" ml={1}>
								Поддержка
							</Typography>
						)}
						{!!countChats && <span className="side-bar-count">{countChats}</span>}
					</MenuItem>
				</NavLink> */}
                <NavLink
                    to={`/${ERoutes.Support}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <TextsmsIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Поддержка
                            </Typography>
                        )}
                        {!!countChats && <span className="side-bar-count">{countChats}</span>}
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={`/${ERoutes.Map}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <FmdGoodIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Карта
                            </Typography>
                        )}
                    </MenuItem>
                </NavLink>
                <NavLink
                    to={`/${ERoutes.Archive}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <InventoryIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Архив
                            </Typography>
                        )}
                    </MenuItem>
                </NavLink>
                {!!process.env.REACT_APP_URL_EXTERNAL_FORM && (
                    <NavLink
                        to={`/${ERoutes.ExternalForm}`}
                        className={getNavLinkClass}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <DynamicFormIcon fontSize="large" />
                            </ListItemIcon>
                            {isOpen && (
                                <Typography
                                    variant="inherit"
                                    ml={1}
                                >
                                    Внешняя форма
                                </Typography>
                            )}
                        </MenuItem>
                    </NavLink>
                )}
                <NavLink
                    to={`/${ERoutes.Companies}`}
                    className={getNavLinkClass}
                >
                    <MenuItem>
                        <ListItemIcon>
                            <LibraryBooksIcon fontSize="large" />
                        </ListItemIcon>
                        {isOpen && (
                            <Typography
                                variant="inherit"
                                ml={1}
                            >
                                Компании
                            </Typography>
                        )}
                    </MenuItem>
                </NavLink>
                {isOwner || isCompanyOwner ? (
                    <NavLink
                        to={`/${ERoutes.Accounting}/${ERoutes.Deposits}`}
                        className={getNavLinkClass}
                    >
                        <MenuItem>
                            <ListItemIcon>
                                <DescriptionOutlinedIcon fontSize="large" />
                            </ListItemIcon>
                            {isOpen && (
                                <Typography
                                    variant="inherit"
                                    ml={1}
                                >
                                    Бухгалтерия
                                </Typography>
                            )}
                        </MenuItem>
                    </NavLink>
                ) : null}
            </MenuList>
        </Paper>
    );
}

export default SideBar;
