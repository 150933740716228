import { workersApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import type { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';
import { IWorker } from 'interfaces/workers';

import { TEmploymentsFilters, TFilters, TState } from './types';

export const initialState: TState = {
    workers: [],
    workerIds: [],
    workerId: '',

    employmentsFilter: 'self_employed',

    loading: false,
    pagination: {
        page: 1,
        size: 50,
        total: 0,
    },
    filters: {
        pk__in: [],
        has_requisite: true,
        payment_details_status__in: [],
    },
};

const slice = createSlice({
    name: 'accounting',
    initialState,
    reducers: (create) => ({
        setWorkers: create.reducer((state, { payload }: PayloadAction<IWorker[]>) => {
            state.workers = payload;
        }),

        setWorkerIds: create.reducer((state, { payload }: PayloadAction<string[]>) => {
            state.workerIds = payload;
        }),

        setWorkerId: create.reducer((state, { payload }: PayloadAction<string>) => {
            state.workerId = payload;
        }),

        fetchWorkers: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { pagination, filters, employmentsFilter } = (getState() as RootState).withdraws;

                const params = getFilledParams({ size: pagination.size, page: pagination.page, ...filters });

                const { data } = await workersApi.getWorkersNew({
                    ...params,
                    order_by: 'last_name',
                    balance__gte: 0.01,
                    payment_details_entity_type__in: employmentsFilter,
                });

                dispatch(setPagination({ ...pagination, total: data.total }));

                dispatch(setWorkers(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.filters = { ...state.filters, ...payload };
        }),

        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),

        setEmploymentsFilter: create.reducer((state, { payload }: PayloadAction<Partial<TEmploymentsFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.employmentsFilter = payload;
        }),
    }),
});

export const {
    setWorkers,
    setWorkerIds,
    setWorkerId,
    fetchWorkers,

    setFilters,
    setPagination,
    setEmploymentsFilter,
} = slice.actions;

export default slice.reducer;
