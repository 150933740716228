import InfoIcon from '@mui/icons-material/Info';
import { Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { memo } from 'react';

import { ECommissionsType } from '../../../../../enums/prices.enum';
import { IIssuePrice, IIssuePriceDetail, TPriceRecipient } from '../../../../../interfaces/issues';
import priceFormatter from '../../../../../utils/helpers/priceFormatter';

import './PricesTable.scss';

function getMoney(amount: number): string {
    if (!amount || Number.isNaN(amount)) return '0';
    return amount?.toLocaleString();
}

type IProps = {
    price: IIssuePrice | null;
    priceType: TPriceRecipient | 'both';
};

function PricesTable({ price, priceType }: IProps) {
    const amountsSumRecipient = (prices: IIssuePriceDetail[]) => prices.reduce((acc, cur) => cur.amount + acc, 0);
    const valuesSumRecipient = (prices: IIssuePriceDetail[]) =>
        prices.reduce((acc, cur) => cur.original_amount + acc, 0);

    const data: {
        totalAmount: string;
        totalDiscount: string;
        totalClientDiscount: string;
        totalPartnerDiscount: string;
        priceDetails: any[];
    } = {
        totalAmount: '',
        totalDiscount: '',
        totalClientDiscount: '',
        totalPartnerDiscount: '',
        priceDetails: [],
    };
    switch (priceType) {
        case 'both': {
            data.totalAmount = getMoney(Number(price?.total_discount_amount.toFixed(2)));
            data.totalDiscount = getMoney(
                Number((Number(price?.total_amount) - Number(price?.total_discount_amount)).toFixed(2)),
            );
            data.priceDetails = price?.details || [];
            break;
        }
        case 'client': {
            const clientPrices = price?.details?.filter((pr) => pr.price_recipient === 'client') || [];

            const amountSumClient = Number(amountsSumRecipient(clientPrices).toFixed(2));
            const valuesSumClient = valuesSumRecipient(clientPrices);

            data.totalAmount = getMoney(Number(amountSumClient));
            data.totalDiscount = getMoney(Number(valuesSumClient - amountSumClient));
            data.totalClientDiscount = getMoney(
                Number(price?.total_client_amount! - price?.total_client_discount_amount!),
            );
            data.priceDetails = clientPrices;
            break;
        }
        case 'partner': {
            const partnerPrices = price?.details?.filter((pr) => pr.price_recipient === 'partner') || [];

            const amountSumPartner = Number(amountsSumRecipient(partnerPrices).toFixed(2));
            const valuesSumPartner = valuesSumRecipient(partnerPrices);

            data.totalAmount = getMoney(Number(amountSumPartner));
            data.totalDiscount = getMoney(Number(valuesSumPartner - amountSumPartner));
            data.totalPartnerDiscount = getMoney(
                Number(price?.total_partner_amount! - price?.total_partner_discount_amount!),
            );
            data.priceDetails = partnerPrices;
            break;
        }
        default: {
            console.log('default: ', data);
        }
    }

    return (
        <TableContainer className="price-table">
            <Table sx={{ minWidth: 400 }}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Услуга</TableCell>
                        <TableCell
                            sx={{ width: '100px' }}
                            align="left"
                        >
                            Цена
                        </TableCell>
                        <TableCell
                            sx={{ width: '100px' }}
                            align="left"
                        >
                            Скидка
                        </TableCell>
                        <TableCell
                            sx={{ width: '170px' }}
                            align="left"
                        >
                            Цена со скидкой
                        </TableCell>
                        <TableCell
                            sx={{ width: '100px' }}
                            align="left"
                        >
                            Количество
                        </TableCell>
                        <TableCell
                            sx={{ width: '100px' }}
                            align="right"
                        >
                            Сумма
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.priceDetails.map((detail) => {
                        return (
                            <TableRow key={detail.id}>
                                <TableCell align="left">
                                    {detail?.is_price_list_changed ? (
                                        <span
                                            className="warning"
                                            title="Прайс-лист услуги не соответствует прайс-листу заявки"
                                        >
                                            <InfoIcon />
                                        </span>
                                    ) : null}
                                    {detail.price.name}{' '}
                                    {detail?.price?.availability_type === 'partner' ? (
                                        <span className="additional">(Партнер)</span>
                                    ) : null}
                                    {detail.price.is_additional ? (
                                        <span className="additional"> (доп. услуга)</span>
                                    ) : null}
                                </TableCell>
                                <TableCell align="left">{priceFormatter(Number(detail.service_amount))}</TableCell>
                                <TableCell
                                    align="left"
                                    sx={{ color: 'rgb(138, 146, 166)' }}
                                >
                                    {`${
                                        detail.discount_type === ECommissionsType.Percentage
                                            ? `${detail.discount ? (detail.discount * 1000) / 10 : 0} %`
                                            : `${detail.discount || 0} ₽`
                                    }`}
                                </TableCell>
                                <TableCell align="left">
                                    {priceFormatter(Number(detail?.service_amount_with_discount))}
                                </TableCell>
                                <TableCell align="left">{detail?.quantity}</TableCell>
                                <TableCell align="right">
                                    {priceFormatter(Number(detail.total_discount_amount))}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <div className="table-caption">
                <Typography sx={{ color: '#8A92A6' }}>Сумма скидки</Typography>
                <Typography
                    color="error"
                    sx={{ fontSize: '16px' }}
                >
                    {priceType === 'client' ? data.totalClientDiscount : data.totalPartnerDiscount} ₽
                </Typography>
            </div>
            <Divider />
            <div className="table-caption">
                <Typography>Итого</Typography>
                <Typography
                    color="green"
                    fontWeight="bold"
                >
                    {priceType === 'client'
                        ? price?.total_client_discount_amount
                        : price?.total_partner_discount_amount}{' '}
                    ₽
                </Typography>
            </div>
        </TableContainer>
    );
}

export default memo(PricesTable);
